import React, {useContext, useEffect, useState} from "react";
import $ from "jquery";
import axiosFetch from "../Common_Functions/Constants";
import {SportsContext} from "./Context/SportsContext";
import {Modal, Button, Form} from 'react-bootstrap';

export const BetPlacPopupSection = ({
                                        showCalculation = false,
                                        stakeValue,
                                        profitData,
                                        hideLoading,
                                        backOrLay,
                                        teamname,
                                        setOdds,
                                        handleStakeChange,
                                        odds,
                                        clearAllSelection,
                                        sportList,
                                        match_id,
                                        oddsk,
                                        loss,
                                        profit,
                                        runnerRowDefault,
                                        rootClassDefault,
                                        profitValue = null,
                                        placeBet,
                                        submitButtonDisable,
                                        teamnames = [],
                                        clearCards = false,
                                        showBetType = ''
                                    }) => {


    const {popupDisplay, setPopupDisplay, betType} = useContext(SportsContext)


    const [stakeValues, setStakeValues] = useState({});
    useEffect(() => {


        if (teamnames?.current?.length === 0) {
            $(".teamExAdd").html('')
        }
        const getStakeValue = async () => {
            const response = await axiosFetch('get_stake_value', 'get');
            setStakeValues(response.data);


        };
        getStakeValue();
    }, []);
    return (<div id="betStakeModelTxt">
        <Modal
            show={popupDisplay}
            onHide={() => {
                setPopupDisplay(false);
                stakeValue.current = 0;
                profitData.current = 0;

                if (typeof clearCards === 'function' && clearCards !== false) {
                    clearCards();
                }
            }}
            dialogClassName="modal-md betPopup"
            aria-labelledby="betStakeModalLabel"
        >
            <Modal.Header closeButton>
                <Modal.Title>Place Bet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hideLoading ? null : <div className="loader1 loaderBet"></div>}
                <div id="placeBetSilp">
                    <div className={`place-bet placeBetSilp pt-2 pb-2 ${backOrLay}`}>
                        <div className="container-fluid container-fluid-5">
                            <div className="row row5 align-items-end">
                                <div id="ShowRunnderName"
                                     className="col-5 text-bold">{showBetType + " " + teamname?.current}</div>
                                <div className="col-7 text-right">
                                    <div className="float-right">
                                        <Button
                                            className="stakeactionminus"
                                            onClick={() => {
                                                const newOdds = (parseFloat(odds) - 0.01).toFixed(2);
                                                setOdds(newOdds);
                                                handleStakeChange({target: {value: stakeValue?.current}}, newOdds);
                                            }}
                                        >
                                            <span className="fa fa-minus"></span>
                                        </Button>
                                        <input
                                            readOnly
                                            type="text"
                                            name="odds"
                                            value={odds}
                                            id="ShowBetPrice"
                                            className="stakeinput amountint"
                                            style={{width: '45px'}}
                                        />
                                        <Button
                                            className="stakeactionplus"
                                            onClick={() => {
                                                const newOdds = (parseFloat(odds) + 0.01).toFixed(2);
                                                setOdds(newOdds);
                                                handleStakeChange({target: {value: stakeValue?.current}}, newOdds);
                                            }}
                                        >
                                            <span className="fa fa-plus"></span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row row5 mt-2">
                                <div className="col-4">
                                    <Form.Control
                                        type="text"
                                        id="stakeValue"
                                        value={stakeValue?.current}
                                        onChange={handleStakeChange}
                                        placeholder="00"
                                        className="stakeinput w-100"
                                    />
                                </div>
                                <div className="col-4">
                                    <Button
                                        onClick={placeBet}
                                        disabled={submitButtonDisable}
                                        className="btn btn-primary btn-block"
                                    >
                                        Submit
                                    </Button>
                                    <input type="hidden" name="sportId" id="sportId" value={sportList?.id}/>
                                    <input type="hidden" name="matchId" id="matchId" value={match_id}/>
                                    <input type="hidden" name="isback" id="isback"
                                           value={backOrLay === 'back' ? 1 : 0}/>
                                    <input type="hidden" name="placeName" id="placeName"
                                           value={teamname?.current?.trim()}/>
                                    <input type="hidden" name="placeName2" id="placeName2" defaultValue=""/>
                                    <input type="hidden" name="odds" id="odds" value={odds}/>
                                    <input type="hidden" name="oddsk" id="oddsk" value={oddsk?.current}/>
                                    <input type="hidden" name="profit" id="profit"
                                           value={backOrLay === 'lay' ? loss?.current : profitData?.current}/>
                                    <input type="hidden" name="loss" id="loss"
                                           value={backOrLay === 'lay' ? profit?.current : loss?.current}/>
                                    <input type="hidden" name="betRowNo" id="betRowNo"
                                           defaultValue={runnerRowDefault?.current}/>
                                    <input type="hidden" name="isfancy" id="isfancy"
                                           value={betType === 'SESSION' ? 0 : 1}/>
                                    <input type="hidden" name="betType" id="betType" value={betType}/>
                                    <input type="hidden" name="betAmount" id="betAmount"
                                           value={stakeValue?.current}/>
                                    <input type="hidden" name="rootClass" id="rootClass"
                                           defaultValue={rootClassDefault?.current}/>
                                </div>
                                {profitValue !== null && (<div className="col-4 text-center pt-1">
                                    <span id="profitData">{profitValue}</span>
                                </div>)}
                            </div>
                            <div className="row row5 mt-2">
                                {Object.values(stakeValues).map((item, id) => (<div className="col-4" key={id}>
                                    <button
                                        key={item.val}
                                        className="btn btn-secondary btn-block mb-2"
                                        value={item.val}
                                        onClick={() => handleStakeChange({target: {value: item.val}})}
                                    >
                                        {item.label}
                                    </button>
                                </div>))}
                            </div>
                            {showCalculation && (
                                <div className="teamExAdd">
                                    {teamnames.current.map((value, index) => (
                                            <>{value}
                                                <div
                                                    className={`
      pull-right 
      ${((backOrLay === 'back' && value !== teamname.current) || (backOrLay === 'lay' && value === teamname.current))
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }`}
                                                >
                                                    {
                                                        ((backOrLay === 'back' && value !== teamname.current) || (backOrLay === 'lay' && value === teamname.current))
                                                            ? (loss.current > 0 ?  `-${loss.current}` : '')
                                                            : profit.current
                                                    }
                                                </div>
                                                <br/>
                                            </>
                                        )
                                    )}
                                </div>
                            )}
                            <div className="mt-3 d-flex justify-content-between align-items-center">
                                <Button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={clearAllSelection}
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>)
}