import React, {useContext,  useState} from 'react';
import {
    generateBackAndLayFunction,
    getSize,
    handleShowRules,  useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";
import FancyBetPopup from "./FancyBetPopup";

const OverByOver = ({
                        gameData,
                        model,
                        showCricketSessionBook,
                        setPopupDisplay, betPlaceStatus,
                        setbackOrLay,
                        setBetOddValue,
                        teamNames,
                        setDefaultTeamName
                    }) => {

    const fancyHideStatus = useFancyHideStatus(model, gameData);

    const [closePopup, setClosePopup] = useState(false)
    const {runnerRowDefault, rootClassDefault, setBetType, oddsk} = useContext(SportsContext);

    const [fancyData, setFancyData] = useState(null)

    if (!gameData || !gameData['over by over']) return null;


    const mainValue = gameData['over by over'];
    let minValue = model.fancy_min_limit;
    let maxValue = model.fancy_max_limit;

    if (mainValue.min) {
        minValue = mainValue.min;
    }

    if (mainValue.max) {
        maxValue = mainValue.max;
    }

    minValue = getSize(minValue, true);
    maxValue = getSize(maxValue, true);

    const arSectionData = mainValue.section || [];


    return (
        <div>
            <div className="market-title">
                <span>Over By Over</span>
                <a href="#" className="m-r-5 game-rules-icon" onClick={() => handleShowRules('Fancyrule')}>
                    <span><i className="fa fa-info-circle float-right"/></span>
                </a>
            </div>

            <div className="table-body SESSION">
                {arSectionData.map((oddsArr, key) => {
                    const teamName = oddsArr.nat;

                    if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                        return null;
                    }


                    let back = 0, lay = 0, backk = '0', layk = '0';
                    const odds = oddsArr.odds || [];

                    oddsArr['back'] = [];
                    oddsArr['lay'] = [];

                    let no = 0;


                    odds.forEach(aValue => {
                        const tno = aValue.tno;
                        if (aValue.otype === "back") {
                            oddsArr['back'][tno] = aValue;
                            back = aValue.odds;
                            backk = aValue.size;
                        } else if (aValue.otype === "lay") {
                            oddsArr['lay'][no] = aValue;
                            lay = aValue.odds;
                            layk = aValue.size;
                            no++;
                        }
                    });

                    let total = (parseFloat(back) + parseFloat(lay));
                    if (model.match_suspend_fancy === 1 || model.match_suspend === 1) {
                        total = 0;
                    }

                    let isSuspendedClass = '';
                    let isSuspended = '';

                    const gstatus = oddsArr.gstatus ? oddsArr.gstatus.toUpperCase() : "";
                    if (gstatus && ['SUSPENDED', 'BALL_RUNNING', 'MATCH-SUSPENDED'].includes(gstatus)) {
                        total = 0;
                    }

                    if (total === 0) {
                        isSuspended = gstatus || 'SUSPENDED';
                        isSuspendedClass = 'suspended';
                    }

                    let backFunctionSes = [], layFunctionSes = [];

                    const runnerRow = `over-by-over-0-${key}`;

                    const n_key_lay = `${model.match_id}-${key}-0`;
                    const n_key_back = `${model.match_id}-${key}-1`;


                    backFunctionSes = generateBackAndLayFunction(total, oddsArr, 'back', teamName, runnerRow, n_key_back, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk);

                    layFunctionSes = generateBackAndLayFunction(total, oddsArr, 'lay', teamName, runnerRow, n_key_lay, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk);

                    back = back > 0 ? parseFloat(back) : "-";
                    backk = backk > 0 ? parseFloat(backk) : "";
                    lay = lay > 0 ? parseFloat(lay) : "-";
                    layk = layk > 0 ? parseFloat(layk) : "";


                    const betPlaceCheck = betPlaceStatus?.current?.[teamName];

                    const fancyListValues = Object.values(betPlaceCheck?.fancy_list || []);

                    const min_value = fancyListValues.length > 0 ? Math.min(...fancyListValues) : null;


                    const get_fancy_session_value =  betPlaceCheck?.fancy_list  ? (
                        <span className="span_fancy_session_value color-font-red">{min_value}</span>
                    ) : null;

                    if (oddsArr.min) {
                        minValue = oddsArr.min;
                    }

                    if (oddsArr.max) {
                        maxValue = oddsArr.max;
                    }

                    minValue = getSize(minValue, true);
                    maxValue = getSize(maxValue, true);
                    const remark = oddsArr.rem || '';

                    return (
                        <div className={`fancy-tripple over-by-over-${key}`} key={key}>
                            <div data-title={isSuspended}
                                 className={`table-row sessionchecksuspended ${isSuspendedClass}`}>
                                <div className="float-left country-name box-6" style={{borderBottom: '0px none'}}>
                                    <p className="m-b-0">
                                        {betPlaceCheck?.bet ? (
                                            <a href="#" className="link-session"
                                               onClick={() => showCricketSessionBook(teamName, model.id, setFancyData)}>
                                                {teamName}
                                            </a>
                                        ) : (
                                            <a href="#">{teamName}</a>
                                        )}
                                    </p>
                                    <p className="m-b-0">
                                        {get_fancy_session_value}
                                    </p>
                                    {remark && (
                                        <div className="market-row">
                                            <marquee className="market-remark">{remark}</marquee>
                                        </div>
                                    )}
                                </div>
                                <div className="box-2 lay float-left text-center" onClick={layFunctionSes[0]}>
                                    <span className="odd d-block">{lay}</span>
                                    <span>{getSize(layk, false)}</span>
                                </div>
                                <div className="box-2 back float-left text-center" onClick={backFunctionSes[0]}>
                                    <span className="odd d-block">{back}</span>
                                    <span>{getSize(backk, false)}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <FancyBetPopup fancyData={fancyData} setFancyData={setFancyData} closePopup={closePopup} setClosePopup={setClosePopup}/>

        </div>
    );
};

export default OverByOver;
