import React, {useEffect, useRef} from 'react'
 import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'

function TimerComponent({data}) {

    const futureTime = useRef(new Date().getTime());
    const length = Object.entries(data).length;

    useEffect(() => {

        if (length > 0 && data.lt > 0) {
            futureTime.current = new Date().getTime() +  data.lt * 1000
        }
    }, [length, data.lt]);

    // digitBlockStyle={{backgroundColor: "white", color: "black", height: "70px"}}
    return <FlipClockCountdown
        digitBlockStyle={{height: "60px", fontWeight: "bold"}}
                               showSeparators={false} showLabels={false} className="float-right"
                               hideOnComplete={false}
                               style={{marginTop: "-85px"}} to={futureTime.current}/>;
}

export default TimerComponent