import React, { useContext, useMemo, useCallback } from "react";
import {
    getSize,
    organiseOdds,
    getExByColor,
    handleShowRules,
    generateBackAndLayFunction
} from "../../../Common_Functions/Constants";
import { SportsContext } from "../../../Components/Context/SportsContext";

function Bookmaker({
                       ar_sectionData,
                       sportList,
                       oddsChange,
                       setBetOddValue,
                       setbackOrLay,
                       teamNames,
                       setDefaultTeamName,
                       setPopupDisplay,
                       bookmakerTeamData
                   }) {
    const { runnerRowDefault, rootClassDefault, setBetType } = useContext(SportsContext);

    if (!teamNames.current['BOOKMAKER']) {
        teamNames.current['BOOKMAKER'] =[]; // Initialize 'BOOKMAKER' as an empty array
    }
    // Memoize bookmaker data

    const bookmakerData = useMemo(() => ar_sectionData?.bookmaker, [ar_sectionData]);
    const sections = bookmakerData?.section || [];

    // Common function to render odds
    const renderOdds = useCallback(
        (oddsArr, key, teamName, runnerRow, tot) => {
            const backFunction = generateBackAndLayFunction(
                tot, oddsArr, 'back', teamName, runnerRow, key, 'BOOKMAKER', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType
            );
            const layFunction = generateBackAndLayFunction(
                tot, oddsArr, 'lay', teamName, runnerRow, key, 'BOOKMAKER', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType
            );

            return (
                <>
                    {/* Back Odds */}
                    {oddsArr.back?.slice(0, 1).map((back, b_key) => {
                        const value_price = tot === 0 ? '-' : back?.odds || '-';
                        const value_size = tot === 0 ? '' : getSize(back?.size || '');

                        // Update odds in oddsChange
                        oddsChange.current[`bookmakerback${key}${b_key}`] = value_price;

                        return (
                            <div
                                key={b_key}
                                data-old={oddsChange.current[`bookmakerback${key}${b_key}`]}
                                data-new={value_price}
                                className={`box-1 back float-left text-center checkdataval`}
                                onClick={backFunction[b_key]}>
                                <span className="odd d-block">{value_price}</span>
                                <span className="d-block">{value_size}</span>
                            </div>
                        );
                    })}

                    {/* Lay Odds */}
                    {oddsArr.lay?.slice(0, 1).map((lay, l_key) => {
                        const value_price = tot === 0 ? '-' : lay?.odds || '-';
                        const value_size = tot === 0 ? '' : getSize(lay?.size || '');

                        // Update odds in oddsChange
                        oddsChange.current[`bookmakerlay${key}${l_key}`] = value_price;

                        return (
                            <div
                                key={l_key}
                                data-old={oddsChange.current[`bookmakerlay${key}${l_key}`]}
                                data-new={value_price}
                                className={`box-1 lay float-left text-center checkdataval`}
                                onClick={layFunction[l_key]}>
                                <span className="odd d-block">{value_price}</span>
                                <span className="d-block">{value_size}</span>
                            </div>
                        );
                    })}
                </>
            );
        },
        [oddsChange, setBetOddValue, setbackOrLay, teamNames, setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType]
    );

    return (
        <>
            {bookmakerData && (
                <div>
                    <div className="market-title">
                        Bookmaker market
                        <p className="float-right mb-0" onClick={() => handleShowRules('Bookmakerrule')}>
                            <i className="fas fa-info-circle"></i>
                        </p>
                    </div>
                    <div className="main-market">
                        <div className="table-header">
                            <div className="float-left country-name box-6 min-max">
                                <b>Min: {bookmakerData.min} Max: {getSize(bookmakerData.max)}</b>
                            </div>
                            <div className="back box-1 float-left text-center"><b>Back</b></div>
                            <div className="lay box-1 float-left text-center"><b>Lay</b></div>
                        </div>
                        <div className="table-body BOOKMAKER">
                            {sections.map((oddsArr, key) => {
                                let isSuspended = "", isSuspendedClass = "";
                                let tot = 1;

                                // Check if suspended
                                if (oddsArr.gstatus === 'SUSPENDED' || sportList.match_suspend_bookmaker === 1 || sportList.match_suspend === 1) {
                                    tot = 0;
                                    isSuspended = "SUSPENDED";

                                    isSuspendedClass = 'suspended';
                                }

                                const teamName = oddsArr.nat || '';
                                teamNames.current['BOOKMAKER'][teamName] = teamName;
                                const teamEx = bookmakerTeamData?.[teamName];


                                const runnerRow = `table-row-${sportList.match_id}-${key}`;

                                // Organize odds
                                const organizedOdds = organiseOdds(oddsArr);
                                const n_key = `${sportList.match_id}-${key}-2`;

                                return (
                                    <div data-title={isSuspended} key={key} className={`table-row table-row-0-${key} ${isSuspendedClass}`}>
                                        <div className="float-left country-name box-4">
                                            <span className="team-name"><b>{teamName}</b></span>
                                            <p><span className="teamEx float-left">{getExByColor(teamEx, true)}</span></p>
                                        </div>
                                        {renderOdds(organizedOdds, n_key, teamName, runnerRow, tot)}
                                    </div>
                                );
                            })}

                            {bookmakerData.rem && (
                                <div className="market-row">
                                    <marquee className="market-remark">{bookmakerData.rem}</marquee>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Bookmaker;
