import React, { useState } from 'react';
import {gameNames} from "../Common_Functions/Constants";
import Header from "./Section/Header";

const CasinoResultReport = () => {
    const [sport, setSport] = useState('');
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Implement your submit logic here, e.g., fetch data based on selected sport and date
        console.log('Selected Sport:', sport);
        console.log('Selected Date:', toDate);
    };

    return (
        <><Header/>
        <div className="col-md-10 report-main-content m-t-5" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <div className="card">
                <div className="card-header">
                    <h4 className="mb-0">Casino Result Reports
                        <span className="export-buttons pull-right"></span>
                    </h4>
                </div>
                <div className="card-body container-fluid container-fluid-5 report-container">
                    <div className="row row5" style={{ padding: '5px' }}>
                        <form onSubmit={handleSubmit} className="row row5 form-horizontal" style={{ marginBottom: '25px', width: '100%' }}>
                            <div className="col-md-3">
                                <select
                                    className="form-control sport"
                                    id="sport"
                                    value={sport}
                                    onChange={(e) => setSport(e.target.value)}
                                >
                                    <option value="">Select Sport</option>
                                    {Object.entries(gameNames).map(([key, value]) => (
                                        <option key={key} value={key}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3"><br />
                                <input
                                    type="date"
                                    name="to_date"
                                    id="to_date"
                                    value={toDate}
                                    className="form-control"
                                    placeholder="Today Date"
                                    onChange={(e) => setToDate(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-3"><br />
                                <button className="btn btn-primary btn-block btn-sm" style={{ width: '100%' }} type="submit">Submit</button>
                            </div>
                        </form>
                        <div className="clearfix"></div><br /><br />
                        <div className="row row5" style={{ width: '100%', marginTop: '10px', padding: '0px' }}>
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table w-100 table-bordered data-table table-responsive" id="casino_game_result_list">
                                        <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Round ID</th>
                                            <th>Result</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {/* Data rows will be rendered here, implement your data rendering logic */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};


export default CasinoResultReport;