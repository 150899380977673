import React, {useContext, useEffect, useState} from 'react';
import axiosFetch from "../Common_Functions/Constants";
import {AuthContext} from "./Context/AuthContext";

const AfterLoginPopup = ({onClose, show}) => {

    const {bannerDetails} = useContext(AuthContext);


    return(
        <div role="dialog" className={`modal fade ${show ? "show" : ''}`} id="AfterLoginPopup" aria-modal="true" style={{display : `${show ? 'block' : ''}`}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <header className="modal-header">
                        {bannerDetails?.FRONT_WELCOME_TEXT_BANNER &&
                            <h6 className="modal-title" dangerouslySetInnerHTML={{__html: bannerDetails?.FRONT_WELCOME_TEXT_BANNER}}></h6>
                        }
                        <button type="button" aria-label="Close" onClick={onClose} data-dismiss="modal" className="close">×</button>
                    </header>
                    <div className="modal-body">
                        {!bannerDetails?.FRONT_WELCOME_BANNER ?
                            <React.Fragment>
                                <div style={{boxShadow: '0 0 5px', padding: '10px'}}>
                                    <h2>Dear Client,</h2>
                                    <h5 className="mb-1">You are requested to login with our official site <a href="# ">{process.env.REACT_APP_NAME}</a> only. Please check the site name before you login.</h5>
                                    <h5 className="mb-1">Thanks for your support.</h5>
                                    <h5 className="mb-1">Team {process.env.REACT_APP_NAME}</h5>
                                </div>
                                <div className="mt-5 font-hindi" style={{boxShadow: '0 0 5px', padding: '10px'}}>
                                    <h2>प्रिय ग्राहक,</h2>
                                    <h5 className="mb-1">आपसे अनुरोध है कि केवल हमारी आधिकारिक साइट <a href="# ">{process.env.REACT_APP_NAME}</a> से लॉगिन करें। लॉगइन करने से पहले साइट का नाम जरूर देख लें।</h5>
                                    <h5 className="mb-1">आपके समर्थन के लिए धन्यवाद।</h5>
                                    <h5 className="mb-1">टीम {process.env.REACT_APP_NAME}</h5>
                                </div>
                                <div className="text-right mt-3">
                                    <button className="btn btn-primary close" aria-label="Close" data-dismiss="modal" style={{minWidth: '100px'}}>OK</button>
                                </div>
                            </React.Fragment>
                            :
                            <img src={"https://dxc247.com/uploads/sites_configuration/" + bannerDetails?.FRONT_WELCOME_BANNER} className="img-fluid"/>
                        }
                    </div>
                </div>
                <span tabIndex="0"></span>
            </div>
        </div>
    );
};

export default AfterLoginPopup;