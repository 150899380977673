import {AuthContext} from "../Context/AuthContext";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import axiosFetch from "../../Common_Functions/Constants";


export const AuthProvider = (props) => {

    const nav = useNavigate();
    const [ACCESS_TOKEN, setACCESS_TOKEN] = useState(localStorage.getItem('token'))
    const [userBalance, setUserBalance] = useState(localStorage.getItem('balance') ?? 0)
    const [exposure, setExposure] = useState(localStorage.getItem('exposure') ?? 0)
    const [casinoList, setCasinoList] = useState(JSON.parse(localStorage.getItem('casinoList')) ?? [])
    const [cricketList, setCricketList] = useState(JSON.parse(localStorage.getItem('cricketList')) ?? []);
    const [bannerDetails, setBannerDetails] = useState({})

    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') ?? false)


    const unAuthorizeHandle = () => {

        localStorage.removeItem('token');

        clearIntervals();
    }

    const balanceInterval = useRef();
    const casinoInterval= useRef();
    const cricketInterval= useRef();

    const clearIntervals = (func = null) => {

        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        localStorage.removeItem('isLoggedIn');


        clearInterval(balanceInterval.current)

        if(func != null){
            return nav('/login');
        }


    }
    const getBalance = () => {

        if (ACCESS_TOKEN !== null) {
            axios.get(`${process.env.REACT_APP_API_URL}user_balance`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {

                    setUserBalance(res.data.balance);
                    setExposure(res.data.exposure);
                    localStorage.setItem('balance', res.data.balance)
                    localStorage.setItem('exposure', res.data.exposure)


                    // clearInterval(balanceInterval.current)
                })
                .catch((error) => {
                    console.log('code is ', error.code)
                    console.log(error)
                    if (error.code === 'ERR_NETWORK') {
                        unAuthorizeHandle();
                        nav('/login');
                        clearInterval(balanceInterval.current);
                    }
                });
        }
    }

    useEffect(() => {

        balanceInterval.current = setInterval(() => {
            getBalance();

        }, 2000);
        return () => {
            clearInterval(balanceInterval.current);
        };


    }, [ACCESS_TOKEN]);

    useEffect(() => {
        getBannerDetails()
    }, []);
    const getCricketList = (token) => {
        try {

            axios.get(process.env.REACT_APP_API_URL + "cricket_list", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },

            }).then((res) => {

                if (typeof cricketInterval === 'undefined') {
                    cricketInterval.current = setInterval(() => getCricketList(token), 5000)
                }
                setCricketList(res.data)
                localStorage.setItem('cricketList', JSON.stringify(res.data))
            }).catch((error) => {

                if (error.code === 'ERR_NETWORK') {
                    clearInterval(casinoInterval.current);
                    clearInterval(cricketInterval.current);

                    //console.clear();
                    unAuthorizeHandle()

                    return nav('/login');
                }
            });
        } catch (e) {

        }
    }
    const  getBannerDetails = async () => {

        await axiosFetch('banner_details', 'get', setBannerDetails)
    }

    const getCasinoList = (token) => {

        axios.get(process.env.REACT_APP_API_URL + "getCasinoList", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            setCasinoList(res.data)
            localStorage.setItem('casinoList', JSON.stringify(res.data))

            if (typeof casinoInterval === 'undefined') {
                casinoInterval.current = setInterval(() => getCasinoList(token), 5000)
            }
        }).catch((error) => {
            if (error.code === 'ERR_NETWORK') {
                // Clear intervals when an error occurs
                clearInterval(casinoInterval.current);
                clearInterval(cricketInterval.current);

                unAuthorizeHandle();
                return nav('/login');
            }
        });
    }


    return (
        <AuthContext.Provider value={{
            ACCESS_TOKEN,
            userBalance,
            exposure,
            casinoList,
            setUserBalance,
            cricketList,
            setACCESS_TOKEN,
            isLoggedIn,
            setIsLoggedIn,
            bannerDetails,
            clearIntervals,
            unAuthorizeHandle,
            getCasinoList,
            getCricketList,
            getBalance
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};