import React, {useContext, useEffect, useRef, useState} from 'react';
import axiosFetch, {
    checkBetPlace, generateBackAndLayFunction,
    getSize,
    handleShowRules, showCricketSessionBook,
    useBetPlaceStatus,
    useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";

const OddEven = ({gameData, model, setPopupDisplay, setDefaultTeamName, setBetOddValue, setbackOrLay}) => {
    const fancyHideStatus = useFancyHideStatus(model, gameData);
    const {runnerRowDefault, rootClassDefault, setBetType, oddsk} = useContext(SportsContext);
    const betEvenCalculation = useRef([])

    const calculateBetevEn = () => {
        const promises = gameData?.['oddeven']?.section.map((oddsArr, mainKey) => {
            const teamName = `${oddsArr.nat}`;
            return axiosFetch('calculate_bet_odd_even', 'post', null, {
                'sport_id': model.id,
                'match_id': model.match_id,
                'teamname': teamName + " - ODD"
            });
        });

        if (typeof promises !== 'undefined') {
            Promise.all(promises)
                .then((results) => {
                    results.forEach((result, index) => {
                        const teamName = gameData['oddeven']?.section[index].nat;
                        betEvenCalculation.current[teamName] = result.data;
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {

        if (Object.keys(model).length > 0) {
            calculateBetevEn();
        }

    }, [model, localStorage.getItem('exposure')]);


    if (!gameData || !gameData?.['oddeven']) return null;


    return (
        <>
            {[gameData?.['oddeven']].map((mainValue, mainKey) => {
                if (!mainValue.status) return null;

                let remark = "";
                let minValue = model.fancy_min_limit;
                let maxValue = model.fancy_max_limit;

                if (mainValue.min) minValue = mainValue.min;
                if (mainValue.max) maxValue = mainValue.max;

                minValue = getSize(minValue, true);
                maxValue = getSize(maxValue, true);

                const oldSectionData = gameData.oddeven?.[mainKey]?.section || [];
                const sectionData = mainValue.section || [];

                return (
                    <div key={mainKey}>
                        <div className="market-title">
                            <span>{mainValue.mname}</span>
                            <a className="m-r-5 game-rules-icon" onClick={() => handleShowRules('Fancyrule')}>
                                <span><i className="fa fa-info-circle float-right"></i></span>
                            </a>
                        </div>

                        <div className="table-header">
                            <div className="float-left country-name box-6"></div>
                            <div className="box-2 float-left back text-center"><b>Odd</b></div>
                            <div className="box-2 float-left back text-center"><b>Even</b></div>
                        </div>

                        <div className="table-body ODDEVEN">
                            {sectionData.map((oddsArr, key) => {
                                const teamName = oddsArr.nat;
                                if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                                    return null;
                                }


                                const teamNameArrs = {};
                                if (teamNameArrs[teamName.trim()]) return null;
                                teamNameArrs[teamName.trim()] = teamName;

                                let isSuspendedClass = '';
                                let isSuspended = '';
                                let back = 0, lay = 0, backk = 0, layk = 0;
                                oddsArr['odd'] = [];
                                oddsArr['even'] = [];
                                let no = 0;
                                let bo = 0;
                                if (oddsArr.odds) {
                                    oddsArr.odds.forEach(aValue => {

                                        if (aValue.otype === "back") {
                                            oddsArr['odd'].push(aValue);
                                            back = aValue.odds;
                                            backk = aValue.size;
                                            bo++;
                                        }
                                        if (aValue.otype === "lay") {
                                            oddsArr['even'].push(aValue);
                                            back = aValue.odds;

                                            lay = aValue.odds;
                                            layk = aValue.size;
                                            no++;
                                        }
                                    });
                                }



                                let total = (parseFloat(back) + parseFloat(lay));
                                if (model.match_suspend_fancy === 1 || model.match_suspend === 1) total = 0;

                                const runnerRow = `odd-even-${mainKey}-${key}`;
                                const teamNames = `${teamName} - Odd`;
                                const teamNameLay = `${teamName} - Even`;
                                const gstatus = oddsArr.gstatus?.toUpperCase() || "";

                                if (["SUSPENDED", "BALL_RUNNING", "MATCH-SUSPENDED"].includes(gstatus)) total = 0;

                                if (total === 0) {
                                    isSuspended = gstatus || 'SUSPENDED';
                                    isSuspendedClass = 'suspended';
                                }

                                let  backFunctionSes= [], layFunctionSes = [];

                                const nKey = `${model.match_id}-0-${key}`;
                                backFunctionSes = generateBackAndLayFunction(total, oddsArr, 'odd', teamNames, runnerRow, nKey, 'ODDEVEN', setBetOddValue, setbackOrLay, [], setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk);


                                layFunctionSes = generateBackAndLayFunction(total, oddsArr, 'even', teamNameLay, runnerRow, nKey, 'ODDEVEN', setBetOddValue, setbackOrLay, [], setPopupDisplay, setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk);


                                if (back > 0) {
                                    back = parseFloat(back);
                                } else {
                                    back = lay;
                                    backFunctionSes = layFunctionSes;
                                }

                                if (backk > 0) {
                                    backk = parseFloat(backk);
                                } else {
                                    backk = layk;
                                }


                                let getFancySessionValue = betEvenCalculation.current?.[teamName] ?? '';


                                if (oddsArr.min) minValue = oddsArr.min;
                                if (oddsArr.max) maxValue = oddsArr.max;

                                minValue = getSize(minValue, true);
                                maxValue = getSize(maxValue, true);
                                if (oddsArr.rem) remark = oddsArr.rem;


                                return (
                                    <div className={`fancy-tripple ${runnerRow}`} key={key}>
                                        <div data-title={isSuspended}
                                             className={`table-row sessionchecksuspended ${isSuspendedClass}`}>
                                            <div className="float-left country-name box-6"
                                                 style={{borderBottom: '0px none'}}>
                                                <p className="m-b-0">
                                                    <a>{teamName}</a>

                                                </p>
                                                <p className="m-b-0">
                                                    <span className="span_fancy_session_value color-font-red"
                                                          style={{color: getFancySessionValue === 0 ? 'black' : undefined}}>
                                                        {getFancySessionValue}
                                                    </span>
                                                </p>
                                                {remark && (
                                                    <div className="market-row">
                                                        <marquee className="market-remark">{remark}</marquee>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="box-2 back float-left text-center"
                                                 onClick={backFunctionSes[0]}>
                                                <span className="odd d-block BackPrice1">{back}</span>
                                                <span>{getSize(backk, false)}</span>
                                            </div>
                                            <div className="box-2 back float-left text-center"
                                                 onClick={layFunctionSes[0]}>
                                                <span className="odd d-block LayPrice1">{lay}</span>
                                                <span>{getSize(parseFloat(layk), false)}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default OddEven;
