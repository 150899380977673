import {SportsContext} from "../Context/SportsContext";
import {useEffect, useRef, useState} from "react";
import {io} from "socket.io-client";
import {checkBetPlace} from "../../Common_Functions/Constants";

export const SportsProvider = (props) => {

    const [popupDisplay, setPopupDisplay] = useState(false)
    const runnerRowDefault = useRef('');
    const rootClassDefault = useRef('');
    const oddsk = useRef(null);
    const [betType, setBetType] = useState('ODDS')
    const [triggerBetPlace, setTriggerBetPlace] = useState(false)

    const [showLoader, setShowLoader] = useState(true)

    const betPlaceStatus = useRef({}); // State for checkBetPlace results

    const  betPlaceStatusCheck  = (sportList, data, teamname = null) => {

        const fetchAllBetPlaceStatus = async () => {

            if (data && Array.isArray(data) && teamname === null) {
                const sections = [];

                if (data['normal'] !== undefined && data['normal'].section) {
                    sections.push(...data['normal'].section);
                }

                if (data['over by over'] !== undefined && data['over by over'].section) {
                    sections.push(...data['over by over'].section);
                }

                if (sections.length > 0) {
                    const betStatuses = await Promise.all(sections.map(async (oddsArr) => {
                        const betPlace = await checkBetPlace(oddsArr.nat, sportList.id);
                        return { teamName: oddsArr.nat, status: betPlace.data };
                    }));

                    const betPlaceStatusMap = betStatuses.reduce((acc, item) => {
                        acc[item.teamName] = item.status;
                        return acc;
                    }, {});

                    betPlaceStatus.current = betPlaceStatusMap;
                }
            } else if (data && Array.isArray(data) && teamname !== null) {
                const betPlace = await checkBetPlace(teamname, sportList.id);


                betPlaceStatus.current[teamname] = betPlace.data;



            }
        };

        fetchAllBetPlaceStatus();

        return betPlaceStatus;
    }


    const sports_socket = io(process.env.REACT_APP_CRICKET_WEBSOCKET_URL)



    const getSportOldData = (type, matchID, newData) => {
        let apiDataOld;

        if (localStorage.hasOwnProperty(matchID)) {
            apiDataOld = JSON.parse(localStorage.getItem(matchID));
        } else {
            apiDataOld = newData;
            localStorage.setItem(matchID, JSON.stringify(newData));
        }

        return apiDataOld;
    };


    return (
        <SportsContext.Provider value={{betPlaceStatusCheck, showLoader, setShowLoader, betPlaceStatus, getSportOldData, sports_socket, betType, triggerBetPlace, setTriggerBetPlace, setBetType, popupDisplay, setPopupDisplay, runnerRowDefault, rootClassDefault, oddsk}}>
            {props.children}
        </SportsContext.Provider>
    );
};