import CasinoLayout from "../Section/Casino/CasinoLayout";
import {useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    getExByColor, getExBySingleTeamNameCasino,

    getExByTeamNameForCasino, resetBetFields,

} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";
import AndarBaharVideoCards from "../../Components/AndarBaharVideoCards";

const Dum10 = () => {
        const [roundId, setRoundId] = useState('')
        const defaultValues = [{odds: {back: 0, lay: 0}, status: '', amounts: ""}, {odds: 0, status: "", amounts: ""}]

        const [totalPlayers, setTotalPlayers] = useState({
            "NEXT": {...defaultValues[0], name: "Next Total 10 or More"},
            'EVEN': defaultValues[1],
            'ODD': defaultValues[1],
            'RED': defaultValues[1],
            'BLACK': defaultValues[1],
            'sum': '',
            'card_count': 0,
            'card_image': process.env.REACT_APP_CARD_PATH + "1.png"

        })

        const roundIdSaved = useRef(null);

        const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

        const [cards, setCards] = useState({});

        const stakeValue = useRef(0);
        const [odds, setOdds] = useState(0)

        const [backOrLay, setbackOrLay] = useState('back')
        const [sportList, setSportList] = useState({})
        const {match_id} = useParams();
        const {
            setBetType,
            setPopupDisplay,

        } = useContext(SportsContext)
        const [hideLoading, setHideLoading] = useState(true)


        const teamNames = useRef(["Player A", "Player B"])

        const [data, setData] = useState([]);
        const [playerA, setPlayerA] = useState(0); // Example player A value
        const [playerStatuses, setPlayerStatuses] = useState({"Player A": '', "Player B": ''});
        const [playerA_Back, setPlayerA_Back] = useState(0);
        const [playerB_Back, setPlayerB_Back] = useState(0);
        const [playerA_Lay, setPlayerA_Lay] = useState(0);
        const [playerB, setPlayerB] = useState(0); // Example player B value

        const [playerB_Lay, setPlayerB_Lay] = useState(0);
        const remark = useRef('Welcome');
        const [lastResult, setLastResult] = useState({});
        const teamname = useRef('');
        const loss = useRef(0);
        const profit = useRef(0);
        const profitData = useRef(0);


        useEffect(() => {
            setBetType('BOOKMAKER')

            if (data?.sub) {

                setTotalPlayers((prevState) => {


                    Object.entries(prevState).map(([index, value]) => {

                        if (index === 'NEXT') {
                            prevState[index] = {
                                ...prevState[index],
                                name: data.sub[0].nat,
                                odds: {back: data.sub[0].b, lay: data.sub[0].l},
                                status: data.sub[0].gstatus === 'OPEN' ? '' : 'suspended-box'
                            }
                        }
                        if (index === 'sum') {

                            prevState[index] = data.csum
                        }
                        if (index === 'card_count') {
                            prevState[index] = data.lcard.split(",").length
                        }
                        if (index === 'card_image') {
                            prevState[index] = process.env.REACT_APP_CARD_PATH + data.card + ".png";
                        }

                        if (['EVEN', 'RED', 'ODD', 'BLACK'].includes(index)) {

                            const founddata = data.sub.find(item => item.nat.toUpperCase() === index)
                            if (founddata) {
                                prevState[index] = {
                                    ...prevState[index],
                                    odds: founddata.b,
                                    status: founddata.gstatus === 'OPEN' ? '' : 'suspended-box'

                                }

                            }

                        }


                    })


                    return prevState


                })

            }

            if (data.card) {
                const cardArray = data.card.split(",").map(item => item.trim());
                setCards({
                    playerA: cardArray.slice(0, 3),
                    playerB: cardArray.slice(3, 6),
                });
                remark.current = data.remark || 'Welcome';
            }
        }, [data]);

        const exposure = localStorage.getItem('exposure');
        const sportLength = Object.keys(data).length;

        const updateAmounts = async () => {
            const getEx = await getExByTeamNameForCasino(sportList.id, roundId, totalPlayers.NEXT.name, match_id, 'NEXT_TOTAL')
            setTotalPlayers((prevState) => {

                prevState['NEXT'] = {...prevState['NEXT'], amounts: getEx?.data || ''}

                return prevState


            })


            const getSingle = await getExBySingleTeamNameCasino(sportList.id, roundId, '', match_id, '')


            setTotalPlayers((prevState) => {

                ['EVEN', 'ODD', 'RED', 'BLACK'].forEach((value, index) => {
                    const founddata = getSingle.data.find(item => item.type === value)

                    if (founddata) {

                        prevState[value] = {...prevState[value], amounts: founddata.total_amount}
                    }
                    else{
                        prevState[value] = {...prevState[value], amounts: ''}
                    }

                })


                return prevState


            })


        }


        useEffect(() => {

            if (data?.sub && sportList?.id) {
                updateAmounts();
            }
        }, [totalPlayers.NEXT.name, exposure, sportLength, roundId]);


        const openPopup = (isBakOrLay, teamnam, oddvalue) => {


            if (parseFloat(oddvalue) > 0) {
                roundIdSaved.current = roundId
                setbackOrLay(isBakOrLay)
                setPopupDisplay(true);
                teamname.current = teamnam
                setOdds(oddvalue)
            } else {
                Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')

            }


        }
        const casinoBetDataNew = (event, new_odds) => {
            stakeValue.current = event.target.value
            if (backOrLay === 'back') {


                loss.current = stakeValue.current;


                profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

            } else {

                profit.current = profitData.current = stakeValue.current;


                loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
            }


        }


        const placeBet = () => {

            setHideLoading(false)
            if (playerStatuses[teamname.current] === 'suspended') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');
                setPopupDisplay(false);
                setHideLoading(true);
                resetBetFields(profitData, stakeValue)
                return;
            }

            if (roundIdSaved.current !== roundId) {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')
                setPopupDisplay(false)
                setHideLoading(true);
                resetBetFields(profitData, stakeValue)
                return;
            }


            setSubmitButtonDisable(true)

            const postdata = {

                "sportId": sportList.id,
                "matchId": roundId,
                "isback": backOrLay === 'back' ? 1 : 0,
                "placeName": teamname.current,
                "placeName2": null,
                "odds": odds,
                "oddsk": 0,
                "profit": parseFloat(profit.current).toFixed(2),
                "loss": parseFloat(loss.current).toFixed(2),
                "betType": "BOOKMAKER",
                "bet_side": backOrLay.toUpperCase(),
                "betAmount": parseFloat(stakeValue.current),
                "type": match_id.toUpperCase(),
                "matchType": match_id.toLowerCase(),

            }

            axiosFetch('casino/store', 'post', null, postdata)
                .then((res) => {
                    if (res.data.status === true) {
                        updateAmounts()

                        resetBetFields(profitData, stakeValue)
                        Notify("Bet Placed Successfully", null, null, 'success')
                    } else {

                        resetBetFields(profitData, stakeValue)
                        Notify(res.data.msg, null, null, 'danger')
                    }
                    setHideLoading(true)
                    setSubmitButtonDisable(false)
                    setPopupDisplay(false)
                })


        }

        return (
            <CasinoLayout raceClass="duskadum new-casino race" data={data} roundId={roundId} setRoundId={setRoundId}
                          sportList={sportList}
                          setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
                <div className="video-container" style={{minHeight: '0px'}}>
                    <div className="video-overlay" style={{top: '-400px'}}>
                        <div className="casino-video-cards">
                            <div className="dkd-total mb-1 mt-1 text-white">
                                <div>
                                    <div>
                                        <div>Curr. Total:</div>
                                        <div className="numeric text-playerb">{totalPlayers.sum}</div>
                                        {/* Placeholder for $csum */}
                                    </div>
                                    <div>Card #: {totalPlayers.card_count}</div>

                                </div>
                                <div>{totalPlayers.NEXT.name}</div>

                            </div>
                            <div className="card-inner" data-current-slide="0"> {/* Placeholder for count($ar_lcard) */}
                                <div className="ms-4">

                                    {data?.lcard && (
                                        <AndarBaharVideoCards dontSplit={true} cards={data?.lcard} player={"Bahar"}
                                                              hidePlayers={true}/>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className="casino-video-current-card">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={totalPlayers.card_image} alt="Current Card"/> {/* Placeholder for $C1 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="casino-detail">
                    <div className="casino-table">
                        <div className="casino-table-box">
                            <div className="casino-table-header">
                                <div className="casino-nation-detail"></div>
                                <div className="casino-odds-box back">Back</div>
                                <div className="casino-odds-box lay">Lay</div>
                            </div>
                            <div className="casino-table-body">
                                <div className="casino-table-row ">
                                    <div className="casino-nation-detail">
                                        <div className="casino-nation-name">{totalPlayers.NEXT.name}</div>
                                        <div>{getExByColor(totalPlayers.NEXT.amounts)}</div>
                                    </div>
                                    <div className={`casino-odds-box back ${totalPlayers.NEXT.status}`}>
                                        <span className="casino-odds">{totalPlayers.NEXT.odds.back}</span>
                                    </div>
                                    <div className={`casino-odds-box lay ${totalPlayers.NEXT.status}`}>
                                        <span className="casino-odds">{totalPlayers.NEXT.odds.lay}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="casino-table-box mt-3">
                            <div className="casino-table-left-box">
                                {["EVEN", "ODD"].map((key) => (
                                    <div className="aaa-odd-box" key={key}>
                                        <div
                                            className="casino-odds text-center">{totalPlayers[key].odds.back || totalPlayers[key].odds}</div>
                                        <div
                                            className={`casino-odds-box back casino-odds-box-theme ${totalPlayers[key].status}`}>
                                            <span className="casino-odds">{key}</span>
                                        </div>
                                        <div
                                            className="casino-odds text-center mb-2">{getExByColor(totalPlayers[key].amounts)}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="casino-table-right-box">
                                {["RED", "BLACK"].map((key) => (
                                    <div className="aaa-odd-box" key={key}>
                                        <div
                                            className="casino-odds text-center">{totalPlayers[key].odds.back || totalPlayers[key].odds}</div>
                                        <div
                                            className={`casino-odds-box back casino-odds-box-theme ${totalPlayers[key].status}`}>
                                            <div className="casino-odds">
                                <span className="card-icon ms-1">
                                    <span
                                        className={key === "RED" ? "card-red" : "card-black"}>{key === "RED" ? '{' : '}'}</span>
                                </span>
                                                <span className="card-icon ms-1">
                                    <span
                                        className={key === "RED" ? "card-red" : "card-black"}>{key === "RED" ? '[' : ']'}</span>
                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="casino-odds text-center mb-2">{getExByColor(totalPlayers[key].amounts)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <marquee scrollamount="3" className="casino-remark m-b-10">{remark.current}</marquee>
                    <div className="casino-last-result-title">
                        <span>Last Result</span>
                    </div>
                    <div className="casino-last-results">
                        <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                    </div>
                </div>
                <BetPlacPopupSection placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                     teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                     teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                     profitData={profitData}
                                     setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

            </CasinoLayout>
        );

    }
;
export default Dum10;
