import React, {useEffect, useState} from 'react';
import axios from 'axios';
import TimerComponent from "./CountdownTimerComponent";

const CasinoVideo = ({gamename, data, showCasinoReconnectModal}) => {
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        // Function to check if the view is mobile

        // Fetch token and generate video URL

        const fetchVideoUrl = async () => {
            return ;
            try {
                const envUrl = process.env.REACT_APP_EXPRESS_URL; // Replace with your API URL
                const response = await axios.get(`${envUrl}/getToken`);
                const token = response.data;

                const url = `${process.env.REACT_APP_CASINO_VIDEO_URL}/play/?sid=s1&id=${gamename.toLowerCase()}&auth=${token}`;
                setVideoUrl(url);

            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };


        if (showCasinoReconnectModal === false) {


             fetchVideoUrl();
        } else {
            setVideoUrl('');
        }


    }, [gamename, showCasinoReconnectModal]);

    return (
        <>

            <div className="casino-video" style={{backgroundColor : 'black'}}>

                <iframe
                    style={{width: '100%', height: '200px'}}
                    className="casino-video"
                    src={videoUrl}
                    allowFullScreen
                ></iframe>
                <TimerComponent data={data}/>


            </div>


        </>
    );
};

export default CasinoVideo;
