import CasinoLayout from "../Section/Casino/CasinoLayout";
import {React, useContext, useEffect, useRef, useState} from "react";

import {CasinoLastResult} from "../Section/Casino/CasinoLastResult";

import axiosFetch, {
    cardGenerate,
    cardMap,
    getExByColor,
    getExBySingleTeamNameCasino,
    resetBetFields
} from "../../Common_Functions/Constants";
import {useParams} from "react-router-dom";
import {SportsContext} from "../../Components/Context/SportsContext";
import {BetPlacPopupSection} from "../../Components/BetPlacPopupSection";

import Notify from "../../js/Notify";

const ThreecardJ = () => {
    const [roundId, setRoundId] = useState('')

        const clearCards = () => {

        cardSelected.current = [];
    }


    const [totalPlayers, setTotalPlayers] = useState({
        "YES": {status: "", odds: 0, amounts: '', cards: cardGenerate()},
        "NO": {status: "", odds: 0, amounts: '', cards: cardGenerate()}

    })


    const cardSelected = useRef([])


    const roundIdSaved = useRef(null);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    const [cards, setCards] = useState({});

    const stakeValue = useRef(0);
    const [odds, setOdds] = useState(0)

    const [backOrLay, setbackOrLay] = useState('back')
    const [sportList, setSportList] = useState({})
    const {match_id} = useParams();
    const {
        betType,
        setBetType,
        setPopupDisplay,

    } = useContext(SportsContext)
    const [hideLoading, setHideLoading] = useState(true)


    const teamNames = useRef(["Player A", "Player B"])

    const [data, setData] = useState([]);
    const remark = useRef('');
    const [lastResult, setLastResult] = useState({});
    const teamname = useRef('');
    const loss = useRef(0);
    const profit = useRef(0);
    const profitData = useRef(0);


    useEffect(() => {


        if (data?.sub) {

            remark.current = data?.remark || 'Welcome';

            setTotalPlayers((prevState) => {

                const prevPlayers = Object.entries(prevState).map(([index,value], i) => {
                    const values_are = {...value}


                    values_are.odds = data?.sub[i]?.b;
                    values_are.status = data?.sub[i]?.gstatus === 'OPEN' ? '' : 'suspended-box';


                    return [index, values_are];

                })

                return Object.fromEntries(prevPlayers)


            })


        }
        if (data.card) {
            const cardArray = data.card.split(",").map(item => item.trim());
            setCards({
                playerA: cardArray.slice(0, 3),
                playerB: cardArray.slice(3, 6),
            });

        }
    }, [data]);

    const exposure = localStorage.getItem('exposure');
    const sportLength = Object.keys(data).length;

    const updateAmounts = async () => {

        const pr = [await getExBySingleTeamNameCasino(sportList.id, roundId, 'YES', match_id, 'YES'), await getExBySingleTeamNameCasino(sportList.id, roundId, 'NO', match_id, 'NO')
        ]

        setTotalPlayers((prevState) => {


            const setPlayer =Object.entries(prevState).map(([index, value], key) => {


                const d = pr[key].data
                if(d){

                    value.amounts = d
                }
                else{

                    value.amounts = ''
                }


                return [index, value];



            })

            return Object.fromEntries(setPlayer)
        })






    }


    useEffect(() => {

        if (data?.sub && sportList?.id) {


            updateAmounts()

        }
    }, [exposure, sportLength, roundId]);


    const openPopup = (isBakOrLay, teamnam, oddvalue, betType) => {

        setBetType(betType)

        const prev = { ...cardSelected.current };

        if (!prev[betType] && Object.keys(prev).length < 2) {
            prev[betType] = [];
        }

// Check if the array already includes the value and if betType is the same as the previous one
        const previousBetType = Object.keys(prev)[0]; // Assuming the first key is the previous bet type

        if (previousBetType === betType && !prev[betType].includes(teamnam)) {
            // If not, push the value into the array
            prev[betType].push(cardMap(teamnam, false));
        }
                cardSelected.current = prev


        if(cardSelected.current?.[betType]?.length < 3){
            return
        }



        // if (parseFloat(oddvalue) > 0) {
        roundIdSaved.current = roundId
        setbackOrLay(isBakOrLay)
        setPopupDisplay(true);
        teamname.current = cardSelected.current[betType].join(' ')
        setOdds(oddvalue)
        // } else {
        //     Notify("Odds Value Change Bet Not Confirm", null, null, 'danger')
        //
        // }


    }
    const casinoBetDataNew = (event, new_odds) => {
        stakeValue.current = event.target.value
        if (backOrLay === 'back') {


            loss.current = stakeValue.current;


            profit.current = profitData.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)

        } else {

            profit.current = profitData.current = stakeValue.current;


            loss.current = (parseFloat(odds - 1) * stakeValue.current).toFixed(2)
        }


    }

    const renderCards = (cards, player) => (
        <div className="flip-card-container">
            {cards?.map((card, index) => {
                const imgSrc = card ? `/img/casino/cards/${card}.png` : '/img/casino/cards/1.png';
                return (
                    <div className="flip-card" key={index}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgSrc} alt={`${player} card ${index + 1}`}/>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const placeBet = () => {


        const notify = (status = 'suspended') => {
            if (status === 'suspended') {
                Notify(`Bet Not Confirmed. Reason: Game Suspended`, null, null, 'danger');


            } else {
                Notify("Bet Not Confirm Reason Game Change", null, null, 'danger')

            }
            setPopupDisplay(false);
            setHideLoading(true);
            setSubmitButtonDisable(false)
            clearCards()
            resetBetFields(profitData, stakeValue)

        }



        setHideLoading(false)
        if (totalPlayers['YES'].status !== '') {
            notify()
            return;
        }

        if (roundIdSaved.current !== roundId) {
            notify('confirm')

            return;
        }


        setSubmitButtonDisable(true)

        const postdata = {

            "sportId": sportList.id,
            "matchId": roundId,
            "isback": 'back',
            "placeName": teamname.current,
            "placeName2": null,
            "odds": odds,
            "oddsk": 0,
            "profit": betType === 'NO' ? parseFloat(loss.current).toFixed(2) : parseFloat(profit.current).toFixed(2),
            "loss": parseFloat(loss.current).toFixed(2),
            "betType": betType,
            "bet_side": backOrLay.toUpperCase(),
            "betAmount": parseFloat(stakeValue.current),
            "type": match_id.toUpperCase(),
            "matchType": match_id.toLowerCase(),

        }

        axiosFetch('casino/store', 'post', null, postdata)
            .then((res) => {
                if (res.data.status === true) {

                    resetBetFields(profitData, stakeValue)
                    clearCards()
                    Notify("Bet Placed Successfully", null, null, 'success')
                } else {


                    resetBetFields(profitData, stakeValue)
                    clearCards()
                    Notify(res.data.msg, null, null, 'danger')
                }
                setHideLoading(true)
                setSubmitButtonDisable(false)
                setPopupDisplay(false)
                clearCards()
                updateAmounts()
            })


    }

    return (
        <CasinoLayout hideRules={true} raceClass="new-casino poker-20" data={data} roundId={roundId} setRoundId={setRoundId}
                      sportList={sportList}
                      setSportList={setSportList} setData={setData} setLastResult={setLastResult}>
            <div className="video-container" style={{minHeight: '0px'}}>
                <div className="video-overlay" style={{top: '230px'}}>
                    <div className="casino-video-cards">
                        <div>

                            {renderCards(cards.playerA, "Player A")}
                        </div>

                    </div>
                </div>
            </div>

            <div className="card-content m-t-5 m-b-5 tpjudgement">
                {Object.entries(totalPlayers).map(([yes,value], index) => {

                    const selectedKey = yes;

                    return (
                        <table data-title="SUSPENDED" key={index}
                               className={`table main-table table-bordered ${value.status}`}>
                            <tbody>
                            <tr style={{backgroundColor: index === 0 ? 'rgb(114, 187, 239)' : 'rgb(250, 169, 186)'}}>
                                <td colSpan="2" className="text-center brate">
                                    <span>{value.odds}</span> {/* Replace static value here */}
                                </td>
                            </tr>
                            <tr style={{backgroundColor: index === 0 ? 'rgb(114, 187, 239)' : 'rgb(250, 169, 186)'}}>
                                <td width="10%">
                                    <div id="nation1" className="p-title text-center">{selectedKey}</div>
                                    <span className="d-block text-center"
                                          style={{color: 'black'}}>{getExByColor(value.amounts)}</span>
                                </td>
                                <td id="andar-box" className="text-center" style={{padding: '5px'}}>
                                    {value.cards.map((value1, index1) => {

                                        return (
                                            <span key={index1} className="game-section c_cursor m-r-5"
                                                  onClick={() => openPopup(index === 0 ? 'back': 'lay', value1[0], value.odds, selectedKey)}>
                                <img src={value1[1]} style={
                                    cardSelected.current?.[cardMap(selectedKey, false)]?.includes(cardMap(value1[0], false)) ?
                                        {width: '33px', height: '40px', border: "1px solid var(--bg-success)"}
                                        :
                                        {width: '33px', height: '40px'}
                                } className="card-image"
                                     alt="A"/>
                            </span>
                                        )
                                    })}

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    )
                })}

                <marquee scrollamount="3" className="casino-remark">{remark.current}</marquee>


                <div className="casino-last-result-title">
                    <span>Last Result</span>
                </div>
                <div className="casino-last-results">
                    <CasinoLastResult sportList={sportList} lastResults={lastResult} data={data}/>
                </div>
            </div>
            <BetPlacPopupSection showBetType={betType} placeBet={placeBet} profitValue={profitData.current} profit={profit} loss={loss}
                                 teamnames={teamNames} handleStakeChange={casinoBetDataNew} hideLoading={hideLoading}
                                 teamname={teamname} backOrLay={backOrLay} stakeValue={stakeValue} odds={odds}
                                 profitData={profitData} clearCards={clearCards}
                                 setOdds={setOdds} submitButtonDisable={submitButtonDisable}/>

        </CasinoLayout>
    );

};

export default ThreecardJ;
