import React, {useContext,  useState} from 'react';
import {

    generateBackAndLayFunction,
    getSize,
    handleShowRules,  useFancyHideStatus
} from "../../../Common_Functions/Constants";
import {SportsContext} from "../../../Components/Context/SportsContext";
import FancyBetPopup from "./FancyBetPopup";


const FancySession = ({data, sportList,  betPlaceStatus, setDefaultTeamName, setBetOddValue, showCricketSessionBook, setbackOrLay, teamNames, setPopupDisplay }) => {
    const fancyHideStatus = useFancyHideStatus( sportList, data);
    const { runnerRowDefault, rootClassDefault, setBetType, oddsk } = useContext(SportsContext);
    const [fancyData, setFancyData] = useState(null)
    const [closePopup, setClosePopup] = useState(false)


    if (!data || !Array.isArray(data) || data['normal'] === undefined) return null;


    const mainValue = data['normal'];


    const ar_sectionData = mainValue.section;
    teamNames.current['SESSION'] = [];

    return (
        <div>
            <div>
                <div className="market-title">
                    <span>Normal</span>
                    <a className="m-r-5 game-rules-icon" onClick={() => handleShowRules('Fancyrule')}>
                        <span>
                            <i className="fa fa-info-circle float-right"></i>
                        </span>
                    </a>
                </div>
                <div className="table-header">
                    <div className="float-left country-name box-6"></div>
                    <div className="box-2 float-left lay text-center">
                        <b>No</b>
                    </div>
                    <div className="box-2 float-left back text-center">
                        <b>Yes</b>
                    </div>
                </div>

                <div className="table-body SESSION">
                    {ar_sectionData.map((oddsArr, key) => {
                        const teamName = oddsArr.nat;
                        teamNames.current['SESSION'][teamName] = teamName;

                        if (fancyHideStatus[oddsArr.sid] || !teamName || teamName.trim() === '') {
                            return null;
                        }

                        const runnerRow = `${sportList.match_id}-${key}`;
                        let isSuspendedClass = '';
                        let back = 0, lay = 0, backk = '0', layk = '0';
                        oddsArr['back'] = [];
                        oddsArr['lay'] = [];


                        if (oddsArr.odds && oddsArr.odds.length > 0) {
                            let no = 0;

                            oddsArr.odds.forEach(a_value => {
                                const tno = a_value.tno;
                                if (a_value.otype === 'back') {

                                    oddsArr['back'][tno] = a_value;
                                    back = a_value.odds;
                                    backk = a_value.size;
                                } else if (a_value.otype === 'lay') {
                                    oddsArr['lay'][no] =a_value;
                                    lay = a_value.odds;
                                    layk = a_value.size;
                                    no++;
                                }
                            });
                        }

                        let totalOdds = parseFloat(back) + parseFloat(lay);
                        if (sportList.match_suspend_fancy === 1 || sportList.match_suspend === 1) {
                            totalOdds = 0;
                        }

                        const gstatus = oddsArr.gstatus ? oddsArr.gstatus.toUpperCase() : '';

                        if (gstatus === 'SUSPENDED' || gstatus === 'BALL_RUNNING' || gstatus === 'MATCH-SUSPENDED') {
                            totalOdds = 0;
                        }

                        if (totalOdds === 0) {
                            isSuspendedClass = 'suspended';
                        }


                        const n_key_lay = `${sportList.match_id}-${key}-0`;
                        const n_key_back = `${sportList.match_id}-${key}-1`;


                        let  backFunctionSes= [], layFunctionSes = [];
                        backFunctionSes =  generateBackAndLayFunction(totalOdds, oddsArr, 'back', teamName, runnerRow, n_key_back, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay,setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk);

                        layFunctionSes = generateBackAndLayFunction(totalOdds, oddsArr, 'lay', teamName, runnerRow, n_key_lay, 'SESSION', setBetOddValue, setbackOrLay, teamNames, setPopupDisplay,setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType, oddsk);

                        const betPlaceCheck = betPlaceStatus?.current?.[teamName];
                        const fancyListValues = Object.values(betPlaceCheck?.fancy_list || []);

                        const min_value = fancyListValues.length > 0 ? Math.min(...fancyListValues) : null;

                        const get_fancy_session_value =  betPlaceCheck?.fancy_list  ? (
                            <span className="span_fancy_session_value color-font-red">{min_value}</span>
                        ) : null;

                        return (
                            <div key={key} className={`fancy-tripple ${isSuspendedClass}`}>
                                <div className={`table-row sessionchecksuspended ${isSuspendedClass}`}>
                                    <div className="float-left country-name box-6" style={{ borderBottom: '0 none' }}>
                                        <p className="m-b-0">
                                            {betPlaceCheck?.bet ? (
                                                <a className="link-session" onClick={() => showCricketSessionBook(teamName, sportList.id, setFancyData)}>
                                                    {teamName}
                                                </a>
                                            ) : (
                                                <a>{teamName}</a>
                                            )}
                                        </p>
                                        <p className="m-b-0">{get_fancy_session_value}</p>
                                    </div>
                                    <div className="box-2 lay float-left text-center" onClick={layFunctionSes[0]}>
                                        <span className="odd d-block">{lay > 0 ? lay : '-'}</span>
                                        <span>{getSize(layk, false)}</span>
                                    </div>
                                    <div className="box-2 back float-left text-center" onClick={backFunctionSes[0]}>
                                        <span className="odd d-block">{back > 0 ? back : '-'}</span>
                                        <span>{getSize(backk, false)}</span>
                                    </div>

                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <FancyBetPopup fancyData={fancyData} setFancyData={setFancyData} closePopup={closePopup} setClosePopup={setClosePopup}/>
        </div>
    );
};

export default FancySession;