import {CommonContext} from "../Context/CommonContext";
import {io} from "socket.io-client";


export const CommonProvider = (props) => {


    const formatDateTime = (date) => {
        if (date !== '' || date !== null) {
            let options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            };

            return new Intl.DateTimeFormat('en-US', options).format(date);
        }
    }


    return (
        <CommonContext.Provider value={{formatDateTime}}>
            {props.children}
        </CommonContext.Provider>
    );
};