import axios from 'axios';
import './css/mobile/login.css';
import {useContext, useState} from 'react';
import AlertBootstrap from "./Components/AlertBootstrap";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./Components/Context/AuthContext";

function Login() {
    const nav = useNavigate();
    const [state, setState] = useState({
        username: '',
        password: ""
    });
    const {getCasinoList, getCricketList, setACCESS_TOKEN, setIsLoggedIn, getBalance} = useContext(AuthContext);

    const [show, setShow] = useState(false);

    const [alertStyle, setAlertStyle] = useState('')
    const [alertMessage, setAlertMessage] = useState('')


    // Function to handle Login button click
    const handleSubmit = (e) => {
        e.preventDefault();


        axios.post(`${process.env.REACT_APP_API_URL}login`, {username: state.username, password: state.password})
            .then((res) => {
                setIsLoggedIn(false)
                localStorage.removeItem('isLoggedIn')
                setACCESS_TOKEN(res.data.token)


                updateLocalStorage(res);
                fetchData(res.data.token);
                getBalance();
                return nav("/");

            })
            .catch((error) => {
                // Check if error response status is 422
                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.errors;

                    let errorMessages = [];

                    for (let property in errors) {
                        // errors[property] is an array of messages for this property
                        errorMessages.push(errors[property].join(", "));
                    }

                    // Join all messages into a single string
                    const errorMessageString = errorMessages.join("\n");

                    // Set the state
                    setAlertMessage(errorMessageString);
                    setShow(true)
                    setAlertStyle('danger')

                }
            });

    };



    const handleInputChange = (event) => {


        const {name, value} = event.target;
        setState((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };

    // Function to handle Login with Demo ID button click
    const handleDemoLogin = async () => {
        try {
            const demoLoginResponse = await demoLogin();



            updateLocalStorage(demoLoginResponse);
            fetchData(demoLoginResponse.data.token);
            setACCESS_TOKEN(demoLoginResponse.data.token)

            getBalance();



            return nav("/");
        } catch (err) {
            console.error(err);
        }
    };

    const demoLogin = () => {
        return axios.post(`${process.env.REACT_APP_API_URL}demo_login`, {}, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    };


    const updateLocalStorage = (response) => {
        localStorage.setItem('token', response.data.token);

        localStorage.setItem('userData', JSON.stringify(response.data.user));
    };


    const fetchData = (token) => {


        getCasinoList(token);
        getCricketList(token);


    }


    return (
        <main>
            <div id="load" style={{visibility: 'hidden'}}>
                <div id="load-inner">
                    <img
                        src="http://dxc247.com/uploads/sites_configuration/C3K6931720187871logo (1).png"
                        className="logo-login"
                        alt="Logo"
                    />
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            </div>
            <div className="login" style={{display: 'flex'}}>
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="loginInner1">
                                    <div className="log-logo m-b-20 text-center">
                                        <img
                                            src="http://dxc247.com/uploads/sites_configuration/C3K6931720187871logo (1).png"
                                            className="logo-login"
                                            alt="Logo"
                                        />
                                    </div>
                                    <div className="featured-box-login featured-box-secundary default loginFrm">
                                        <h4 className="text-center login-title">
                                            LOGIN
                                            <i className="fas fa-hand-point-down"></i>
                                        </h4>
                                        {show && <AlertBootstrap style={alertStyle} message={alertMessage}
                                                                 closeAlert={() => setShow(false)}/>}

                                        <form className="form-horizontal" id="login_form" onSubmit={handleSubmit}>
                                            <div className="mb-4 input-group position-relative">
                                                <input
                                                    name="username"
                                                    type="text"
                                                    className="form-control" onChange={handleInputChange}
                                                    placeholder="Username" defaultValue={state.username}
                                                />
                                                <span className="input-group-text">
                                                    <i className="fas fa-user"></i>
                                                </span>
                                            </div>

                                            <div className="mb-4 input-group position-relative">
                                                <input
                                                    name="password"
                                                    type="password"
                                                    className="form-control" onChange={handleInputChange}
                                                    placeholder="Password" defaultValue={state.password}
                                                />
                                                <span className="input-group-text">
                                                    <i className="fas fa-key"></i>
                                                </span>
                                            </div>

                                            <div className="form-group text-center mb-0">
                                                <button
                                                    type="submit"
                                                    className="btn text-white btn-block" id="login_submit"
                                                    style={{backgroundColor: 'var(--bg-primary)'}}

                                                >
                                                    Login <i className="loginicon ml-2 fas fa-sign-in-alt"></i>
                                                </button>
                                            </div>

                                            <div className="form-group text-center mt-2 mb-0">
                                                <button
                                                    type="button"
                                                    className="btn text-white btn-block"
                                                    style={{backgroundColor: 'var(--bg-primary)'}}
                                                    onClick={handleDemoLogin}
                                                >
                                                    Login with demo ID <i
                                                    className="login_demo_icon ml-2 fas fa-sign-in-alt"></i>
                                                </button>
                                            </div>

                                            <small className="recaptchaTerms">
                                                This site is protected by reCAPTCHA and the Google
                                                <a href="#">Privacy Policy</a> and
                                                <a href="#">Terms of Service</a> apply.
                                            </small>
                                            <p className="mt-2 text-center">
                                                <a href="mailto:info@Dxc247.Com" className="mail-link">
                                                    info@Dxc247.Com <span className="ml-1">| </span>
                                                </a>
                                                <a href="#" target="_blank" className="ml-2">
                                                    <i className="fab fa-android "></i> Download APK
                                                </a>
                                            </p>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="footer footer-login">
                <div className="footer-top">
                    <div className="support-detail">
                        <h2>24X7 Support</h2>
                        <p></p>
                    </div>
                    <div className="social-icons-box">
                        <div className="social-icon">
                            <a href="http://www.facebook.com/dxc247.com" target="_blank">
                                <img
                                    src="http://g1ver.sprintstaticdata.com/v16/static/front/img/social-icons/facebook.png"
                                    alt="Facebook"
                                />
                            </a>
                        </div>

                        <div className="social-icon">
                            <a href="http://www.instagram.com/dxc247.com" target="_blank">
                                <img
                                    src="http://g1ver.sprintstaticdata.com/v16/static/front/img/social-icons/instagram.png"
                                    alt="Instagram"
                                />
                            </a>
                        </div>

                        <div className="social-icon">
                            <a href="http://www.telegram.com/dxc247.com" target="_blank">
                                <img
                                    src="http://g1ver.sprintstaticdata.com/v16/static/front/img/social-icons/telegram.png"
                                    alt="Telegram"
                                />
                            </a>
                        </div>

                        <div className="social-icon">
                            <a href="http://www.twitter.com/dxc247.com" target="_blank">
                                <img
                                    src="http://g1ver.sprintstaticdata.com/v16/static/front/img/social-icons/twitter.png"
                                    alt="Twitter"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Login;