import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from "../../Components/Context/AuthContext";
import axiosFetch from "../../Common_Functions/Constants";
import {Link} from "react-router-dom";
import AfterLoginPopup from "../../Components/AfterLoginPopup";
import CurrentBetModal from "../../Components/CurrentBetModal";

function Header() {
    const dropdownRef = useRef('');  // Ref for the dropdown

    const [currentBetModalOpen, setCurrentBetModalOpen] = useState(false)
    const currentBetRef = useRef(null);

    const [userData, setUserData] = useState(null);
    const [exposureHide, setExposureHide] = useState(localStorage.getItem('exposureHide') ?? true)
    const [balanceHide, setBalanceHide] =  useState(localStorage.getItem('balanceHide') ?? true)

    const {userBalance, exposure, setIsLoggedIn, clearIntervals, isLoggedIn} = useContext(AuthContext);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false); // Close the dropdown
            }
        };

        // Add event listener on mount
        document.addEventListener('click', handleDocumentClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {

        const storedUserData = localStorage.getItem("userData");

        if (storedUserData !== "undefined") {

            setUserData(JSON.parse(storedUserData));
        }


    }, []);


    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
         axiosFetch('logout',  'post')
        setIsLoggedIn(false)

        clearIntervals('navigate');



    }


    const [showModal, setShowModal] = useState(true);

    const toggleModal = () => {
        setShowModal(!showModal)
        setIsLoggedIn(true)

        localStorage.setItem('isLoggedIn', true)

    };



    const showStakePopup = () => {
        // Handle stake popup
    };

    return (
        <>
            <link rel="stylesheet" href="//dxc247.com/build/assets/frontend_styles_mobile-wz-Q5hti.css"/>
            <link rel="stylesheet" href="/mobile/css/mobile_all.css"/>
            <header className="header">
                <div id="notifications"></div>

                {isLoggedIn === false ? (
                    <AfterLoginPopup onClose={toggleModal} show={showModal}/>
                ) : null
                }
                <div className="container-fluid">
                    <div className="row row5 pt-1 pb-1">
                        <div className="logo col-6">
                            <Link to="/" className="router-link-exact-active router-link-active">
                                <i className="fas fa-home mr-1"></i>
                                <img src="../img/logo.png" alt="Exchange" className="img-fluid logo"/>
                            </Link>
                        </div>
                        <div className="col-6 text-right bal-expo">
                        <span className="mb-0">
                            {userData && userData.show_balance === 1 && balanceHide === true && (
                                <div className="show_balance_or_not">
                                    <span className="mr-1">Balance</span>
                                    <b>
                                        {userBalance}
                                    </b>
                                </div>
                            )}
                        </span>
                            <div>
                                {userData && userData.show_exposure === 1 && exposureHide === true && (
                                    <span className="mr-1 show_exposure_or_not">
                                    <u className="accountMenu" onClick={() => setCurrentBetModalOpen(true)}>
                                        Exp: {exposure}
                                    </u>
                                </span>
                                )}
                                <div className="dropdown d-inline-block" ref={dropdownRef}>
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown"
                                       onClick={toggleDropdown}>
                                        <u>{userData ? (userData.is_demo === 0 ? userData.username.toUpperCase() : userData.name) : ''}</u>
                                        <span className="fa fa-angle-down"></span>
                                    </a>
                                    <div  className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                        <a href="/" className="dropdown-item">Home</a>
                                        <Link to="/account-statement" className="dropdown-item">Account Statement</Link>
                                        <Link to="/profit-loss" className="dropdown-item">Profit Loss Report</Link>
                                        <a onClick={() => setCurrentBetModalOpen(true)} className="dropdown-item">Current Bet</a>
                                        <Link to="/bet-history" className="dropdown-item">Bet History</Link>
                                        <Link to="/casino-game-results" className="dropdown-item">Casino Report History</Link>
                                        <a onClick={showStakePopup} className="dropdown-item">Set Button Values</a>
                                        {userData && userData.is_demo === 0 && (
                                            <a href={`/change-password/${userData.id}`} className="dropdown-item">Change
                                                Password</a>
                                        )}
                                        <Link to="/rules" className="dropdown-item">Rules</Link>
                                        <a className="dropdown-item d-xl-none">
                                            Balance
                                            <div className="form-check float-right">
                                                <input type="checkbox" className="form-check-input" onChange={() => {

                                                    setBalanceHide(!balanceHide)
                                                    localStorage.setItem('balanceHide', !balanceHide)
                                                }} defaultValue=""
                                                       checked={userData?.show_balance === 1 && balanceHide} />
                                            </div>
                                        </a>
                                        <a className="dropdown-item d-xl-none">
                                            Exposure
                                            <div className="form-check float-right">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    onChange={() => {
                                                        const newExposureHide = !exposureHide;
                                                        setExposureHide(newExposureHide);
                                                        localStorage.setItem('exposureHide', newExposureHide);
                                                    }}
                                                    defaultValue=""

                                                    checked={userData?.show_exposure === 1 && exposureHide} // Control checked state
                                                />
                                            </div>


                                        </a>
                                        <a className="dropdown-item mt-2 text-danger" onClick={handleLogout}>
                                            <b>Logout</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row5 header-bottom">
                        <div className="col-12">
                            <div className="search-box-container">
                                <div className="search-box float-left">
                                    <input type="text" className="search_input" defaultValue="" />
                                    <a className="search_icon">
                                        <i className="fas fa-search-plus"></i>
                                    </a>
                                </div>
                            </div>
                            <marquee scrollamount="3" className="searchClose">{/* Render announcement */}</marquee>
                        </div>
                    </div>
                    <div className="row row5">
                        <div className="latest-event d-xl-none">
                            {/* Render livewire component or equivalent */}
                        </div>
                    </div>
                </div>

            </header>
            <CurrentBetModal isOpen={currentBetModalOpen} onClose={setCurrentBetModalOpen}/>

        </>
    );
}

export default Header;
