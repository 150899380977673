import React, {useContext, useEffect, useState} from 'react';
import {Tab, Nav,} from 'react-bootstrap';
import Header from "../../Section/Header";
import CasinoVideo from "../../../Components/Casino/CasinoVideo";
import {useParams} from "react-router-dom";
import axiosFetch from "../../../Common_Functions/Constants";
import {Buffer} from 'buffer';
import MatchedBetTable from "./MatchedBetTable";
import CasinoRules from "../../../Components/Casino/CasinoRules";
import CasinoReconnectModalPopup from "../../../Components/Casino/CasinoReconnectModalPopup";
import {CasinoContext} from "../../../Components/Context/CasinoContext";

const CasinoLayout = ({
                          setData = null,
                          data = {},

                          setLastResult = null,
                          sportList = {},
                          setRoundId = null,
                          roundId = 0,
                          setSportList = null,
                          children,
                          ruleImage = null,
                          ruleDescription = null,
                          hideRules = false,
    raceClass= 'teenpatti1day new-casino race'
                      }) => {


        const {casino_socket} = useContext(CasinoContext)
        const [socketDisconnectModal, setSocketDisconnectModal] = useState(false)
        const [showCasinoReconnectModal, setShowCasinoReconnectModal] = useState(false)

        const {match_id} = useParams()

        const length = Object.keys(sportList).length


        const [mybetModel, setMybetModel] = useState([])
        const [showRules, setShowRules] = useState(false);

        useEffect(() => {
            axiosFetch('matched-bet-details/' + sportList.id, 'get', setMybetModel)

        }, [length, localStorage.getItem('exposure')]);


        const handleShowRules = () => setShowRules(true);
        const handleCloseRules = () => setShowRules(false);
        const [key, setKey] = useState('game');

        useEffect(() => {
            const getData = async () => {
                await axiosFetch(`casino_game/${match_id}`, 'get')
                    .then((res) => setSportList(res.data))
            }
            if (setSportList !== null) {
                getData()
            }
        }, [length]);


        useEffect(() => {

            let socket_game = `getUserData${match_id}`;
            casino_socket.emit('setPurposeFor', 'casinos', match_id, '')

            const gameConnect = () => {


                casino_socket.on(socket_game, sportData => {

                    if (sportData !== null) {

                        let fetchedData = JSON.parse(Buffer.from(sportData).toString('utf8'))
                        const parsedData = fetchedData[0]; // Assuming this is the data you want to work with.
                        const processedData = {

                            data: {},
                            lastResults: {},
                        };

                        if (parsedData && Object.keys(parsedData).length > 0) {

                            if (parsedData.hasOwnProperty('t1')) {

                                processedData.data.t1 = parsedData.t1;
                            }
                            if (parsedData.hasOwnProperty('t2')) {
                                processedData.data.t2 = parsedData.t2;
                            } else if (parsedData && parsedData.hasOwnProperty('sub')) {
                                processedData.data = parsedData; // Copy the entire data if 'sub' exists.
                            }

                        }
                        if (fetchedData[1] && fetchedData[1].hasOwnProperty('res')) {

                            processedData.lastResults = fetchedData[1].res.res || fetchedData[1].res;

                        }
                        if (Object.keys(processedData.data).length > 0 && setRoundId !== null) {

                            setRoundId(processedData.data?.mid || processedData?.data?.t1?.gmid)


                        }
                        if (setData !== null) {
                            setData(processedData.data)
                        }


                        if (setLastResult !== null) {
                            setLastResult(processedData.lastResults)
                        }

                    }
                })

            }


            if (Object.keys(sportList).length > 0 && setData !== null) {


                gameConnect();
            }

            if (socketDisconnectModal === true) {

                casino_socket.disconnect();
            }


            casino_socket.on('disconnect', function () {

                // Reconnect logic
                const connectInterval = setInterval(() => {
                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });

            if (length > 0) {
                return () => {

                    console.log('disconnecting ')

                    casino_socket.off(socket_game)
                }
            }

        }, [length, match_id, showCasinoReconnectModal])


        return (
            <>
                <Header/>

                <div className="main-content">
                    <div className="casino-header d-flex justify-content-between">
                        <Nav justify variant="tabs" activeKey={key} onSelect={(selectedKey) => setKey(selectedKey)}>
                            <Nav.Item>
                                <Nav.Link eventKey="game">Game</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="match" className="a_mobile_placed_bets">Placed Bets
                                    ({mybetModel.length})</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <div className="float-right text-right round pr-2">
                            {!hideRules && (
                                <span className="d-block">
                        <a onClick={handleShowRules} className="ml-1" role="button">Rules</a>
                    </span>
                            )}
                            <span className="d-block">
                        Round ID: {roundId}
                    </span>
                        </div>
                    </div>

                    <Tab.Container activeKey={key}>
                        <Tab.Content>
                            <Tab.Pane eventKey="game">
                                <div className={`tab-content ${raceClass}`}>
                                    <div className="casino-title">
                                        <span className="casino-name">{sportList?.match_name}</span>
                                    </div>


                                    <CasinoVideo showCasinoReconnectModal={showCasinoReconnectModal} gamename={match_id}
                                                 data={data}/>

                                    {children}

                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="match">
                                <div className="tab-content teenpatti1day new-casino race">
                                    <MatchedBetTable type={sportList?.match_id} mybetModel={mybetModel}/>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    {/* Rules Modal */}
                    <CasinoRules image={ruleImage} description={ruleDescription} showRules={showRules}
                                 handleCloseRules={handleCloseRules}/>
                    <CasinoReconnectModalPopup setSocketDisconnectModal={setSocketDisconnectModal}
                                               setShowCasinoReconnectModal={setShowCasinoReconnectModal}
                                               show={showCasinoReconnectModal}/>

                </div>
            </>
        )
    }
;

export default CasinoLayout;
