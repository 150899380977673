import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import axiosFetch, {setdecimalPoint} from '../Common_Functions/Constants';
import $ from "jquery";
import {useParams} from "react-router-dom";
import axios from "axios";
import {SportsContext} from "./Context/SportsContext";
import Notify from "../js/Notify";
import {AuthContext} from "./Context/AuthContext";
import {BetPlacPopupSection} from "./BetPlacPopupSection";
import {clear} from "@testing-library/user-event/dist/clear";

const BetPlacePopup = ({
                           setPopupDisplay,
                           individualBetPlaceFetch,
                           odds,
                           setOdds,
                           backOrLay,
                           teamNames,


                           teamname,
                           sportList

                       }) => {
    const {betType, oddsk} = useContext(SportsContext);

    const stakeValue = useRef(0);

    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)
    const [profitValue, setProfitValue] = useState(0)
    const {getBalance} = useContext(AuthContext);
    const [hideLoading, setHideLoading] = useState(true)
    const loss = useRef(0);
    const {match_id} = useParams();
    const profit = useRef(0);
    const profitData = useRef(0);

    const {runnerRowDefault, rootClassDefault} = useContext(SportsContext)


    const handleStakeChange = (event, newodds = null) => {


        const value = event.target.value;
        stakeValue.current = value;

        $("#stakeValue").val(value)

        if (newodds !== null) {
            odds = newodds
        }

        calculateProfit(value, odds);
        let html = '';
        let stakeVal = value;
        var placeName = teamname.current.trim();
        const teamExAddElement = document.querySelector('.teamExAdd');
        let i = 0;


        if (typeof teamNames.current?.[betType] !== 'undefined' && Object.keys(teamNames.current?.[betType])?.length > 0) {


            if (backOrLay === 'back') {

                for (let [teami, item] of Object.entries(teamNames.current[betType])) {

                    let parsedValue = parseFloat($("." + betType).find('.team-name').next().eq(i).text());
                    let lprofit = parseFloat(setdecimalPoint(profit.current));
                    loss.current = parseFloat(setdecimalPoint(stakeVal));

                    let total_stakeVal, total_profit;

                    html += `<br> ${item}`;


                    let isCurrentTeam = placeName.trim() === item.trim();

                    // Handle stake and profit calculation
                    if (isCurrentTeam) {

                        total_profit = (parsedValue >= 0) ? parsedValue + lprofit : lprofit - Math.abs(parsedValue);
                        let textClass = (total_profit >= 0) ? 'text-success' : 'text-danger';

                        html += `<div class="pull-right ${textClass}">${total_profit}</div>`;
                    } else {
                        total_stakeVal = (parsedValue > 0)
                            ? -(Math.abs(parsedValue - loss.current))
                            : -(Math.abs(parsedValue || 0) + loss.current);


                        let textClass = (total_stakeVal >= 0) ? 'text-success' : 'text-danger';

                        html += `<div class="pull-right ${textClass}">${total_stakeVal}</div>`;
                    }
                    i++;
                }


                if (betType !== 'SESSION') {
                    teamExAddElement.innerHTML = html;
                }

            } else {
                for (let [teami, item] of Object.entries(teamNames.current[betType])) {
                    let parsedValue = parseFloat($("." + betType).find('.team-name').next().eq(i).text());
                    let lprofit = parseFloat(setdecimalPoint(profit.current));

                    loss.current = parseFloat(setdecimalPoint(stakeVal));

                    let total_stakeVal, total_profit;

                    html += `<br> ${item}`;

                    let isCurrentTeam = placeName === item;

                    // Handle stake and profit calculation
                    if (isCurrentTeam) {

                        total_profit = (parsedValue > 0) ? parsedValue - lprofit : -(Math.abs(parsedValue) + Math.abs(lprofit));
                        let textClass = (total_profit > 0) ? 'text-success' : 'text-danger';

                        html += `<div class="pull-right ${textClass}">${total_profit}</div>`;
                    } else {
                        total_stakeVal = (parsedValue >= 0)
                            ? Math.abs(parsedValue + loss.current)
                            : Math.abs(loss.current) - Math.abs(parsedValue || 0);

                        let textClass = total_stakeVal >= 0 ? 'text-success' : 'text-danger';

                        html += `<div class="pull-right ${textClass}">${total_stakeVal}</div>`;
                    }
                    i++;
                }

                if (betType !== 'SESSION') {
                    teamExAddElement.innerHTML = html;
                }

            }
        }
        const path = window.location.pathname; // Let's say '/home/products/item'

        const pathElements = path.split('/'); // This gives: ['', 'home', 'products', 'item']

        const secondElement = pathElements[1]; // 'products'
        axios.get(process.env.REACT_APP_URL + ":3000/fetch_data?matchId=" + match_id + "&sports=" + secondElement)


    };


    const handleOddsChange = (event) => {
        const value = event.target.value;

        calculateProfit(stakeValue, value);
    };

    const calculateProfit = (stake, odds) => {
        // Parse odds and stake once for performance
        const parsedOdds = parseFloat(odds);
        const parsedStake = parseFloat(stake);

        let profitCalc = 0;

        if (betType === 'ODDS' || betType === 'ODDEVEN') {
            profitCalc = parsedStake * (parsedOdds - 1);
        } else if (betType === 'SESSION') {
            profitCalc = parsedStake;
        } else if (betType === 'BOOKMAKER' || betType === 'TIED_MATCH') {
            profitCalc = (parsedStake * parsedOdds) / 100;
        } else {
            profitCalc = parsedStake * parsedOdds;
        }

        // Ensure profitCalc is always a number to avoid issues
        profitCalc = isNaN(profitCalc) ? 0 : profitCalc;

        // Store the result directly in the profit reference
        profit.current = profitCalc.toFixed(2);


        // Handle profit/loss logic for back or lay
        if (backOrLay === 'back') {
            if (betType === 'ODDS' ) {
                profitData.current = profit.current
            } else if (betType === 'BOOKMAKER') {

                profitData.current = parsedOdds
            } else if (betType === 'ODDEVEN' ) {
                profitData.current = profit.current
            }



            else if( betType === 'fancy1'){

                profitData.current = (parsedOdds - 1) * 100;
            }
            else if(betType === 'TIED_MATCH') {

                profitData.current = (parsedOdds * parsedStake) / 100;
            }
            else {

                profitData.current = (oddsk.current * parsedStake) / 100;
            }
        } else {
            profitData.current = parsedStake;
        }


        // Use React state to update UI efficiently instead of directly manipulating the DOM
        setProfitValue(profitData.current);  // Add a state to reflect the profit value in the UI
    };

    const clearAllSelection = () => {
        stakeValue.current = 0;
        setOdds(0);
        profit.current = 0;
    };

    const placeBet = () => {

        setSubmitButtonDisable(true)
        const submit = () => {
            const data = {
                sportId: sportList.id,
                matchId: sportList.match_id,
                isback: backOrLay === 'back' ? 1 : 0 ,
                placeName: teamname?.current?.trim(),
                placeName2: '',
                odds: odds,
                oddsk: oddsk.current,
                profit: backOrLay === 'lay' ? loss.current : profitData.current,
                loss: backOrLay === 'lay' ? profit.current : loss.current,
                betRowNo: runnerRowDefault?.current,
                isfancy: betType === 'SESSION' ? 0 : 1,
                betType: betType,
                betAmount: stakeValue.current,
                rootClass: rootClassDefault.current
            };

            data.loss = betType === 'fancy1' ? stakeValue.current : data.loss;


            axiosFetch('betStore', 'post', null, data)
                .then((data) => {


                    if (data.data.status == true) {
                        individualBetPlaceFetch(teamname.current)
                        Notify(data.data.msg, null, null, 'success');



                    } else {


                        Notify(data.data.msg, null, null, 'danger');


                    }

                    getBalance()

                    setHideLoading(true);
                    setSubmitButtonDisable(false)
                    clearAllSelection();
                    setPopupDisplay(false)
                }).catch((error) =>{

                setHideLoading(true);
                setSubmitButtonDisable(false)
                clearAllSelection();
                setPopupDisplay(false)
                console.log(error)
            })


        }

        if (betType === 'ODDS' || betType === 'BOOKMAKER' || betType === 'TIED_MATCH') {
            setHideLoading(false)

                submit()

        } else {
            submit()
        }

    };


    return (
        <BetPlacPopupSection betType={betType} submitButtonDisable={submitButtonDisable} placeBet={placeBet}

        teamname={teamname} backOrLay={backOrLay} sportList={sportList} match_id={match_id} odds={odds} setOdds={setOdds} oddsk={oddsk} profitValue={profitValue} profit={profit}
                             loss={loss} clearAllSelection={clearAllSelection} handleStakeChange={handleStakeChange} hideLoading={hideLoading}
                             profitData={profitData} rootClassDefault={rootClassDefault} runnerRowDefault={runnerRowDefault} stakeValue={stakeValue}
        />
    );
};

export default BetPlacePopup;
