import {
    getExByColor,
    getSize,
    handleShowRules,
    organiseOdds,
    generateBackAndLayFunction,
} from "../../../Common_Functions/Constants";
import React, { useContext, useMemo } from "react";
import { SportsContext } from "../../../Components/Context/SportsContext";


function MatchOdds({
                       ar_sectionData,
                       sportList,
                       oddsChange,
                       setBetOddValue,
                       setbackOrLay,
                       teamNames,
                       setPopupDisplay,
                       setDefaultTeamName,
                       oddsTeamData,
                   }) {
    const { runnerRowDefault, rootClassDefault, setBetType } = useContext(SportsContext);

    // Memoize maxValue and mainValue to avoid recalculating
    const maxValue = useMemo(() => ar_sectionData?.['match_odds']?.['maxb'], [ar_sectionData]);
    const mainValue = useMemo(() => ar_sectionData?.['match_odds'], [ar_sectionData]);

    // Return null if there is no match_odds data
    if (!ar_sectionData?.['match_odds']) return null;
    teamNames.current['ODDS'] = [];

    return (

        <>

            <div className="market-title">
                MATCH_ODDS
                <p className="float-right mb-0" onClick={() => handleShowRules('Matchrule')}>
                    <i className="fas fa-info-circle"></i>
                </p>
            </div>
            <div className="main-market">
                <div className="table-header">
                    <div className="float-left country-name box-6 min-max">
                        <b>Max: {getSize(maxValue)}</b>
                    </div>
                    <div className="back box-1 float-left text-center"><b>Back</b></div>
                    <div className="lay box-1 float-left text-center"><b>Lay</b></div>
                </div>
                <div className="table-body ODDS">
                    {mainValue?.['section']?.map((oddsArr, key) => {
                        const teamName = oddsArr.nat || "";
                        teamNames.current['ODDS'][teamName] = teamName;

                        // Determine if the odds are suspended
                        let isSuspended = "", isSuspendedClass = "";
                        let tot = mainValue.status === "OPEN" ? 1 : 0;

                        if (sportList.match_suspend_odd === 1 || sportList.match_suspend === 1) {
                            tot = 0;
                        }

                        if (tot === 0) {
                            isSuspended = "SUSPENDED";
                            isSuspendedClass = "suspended";
                        }

                        const runnerRow = `table-row-${sportList.match_id}-${key}`;
                        const teamEx = oddsTeamData?.[teamName];
                        const n_key = `${sportList.match_id}-${key}-2`;

                        // Organize odds only if they exist
                        if (oddsArr.odds?.length > 0) {
                            oddsArr = organiseOdds(oddsArr);
                        }

                        // Render back odds
                        const backOdds = oddsArr.back.slice(0, 1).map((back, b_key) => {
                            back.odds = tot === 0 ? '-' : back.odds;
                            back.size = tot === 0 ? '' : back.size;

                            const backFunction = generateBackAndLayFunction(
                                tot, oddsArr, 'back', teamName, runnerRow, n_key, 'ODDS',
                                setBetOddValue, setbackOrLay, teamNames, setPopupDisplay,
                                setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType
                            );

                            setTimeout(() => {
                                oddsChange.current[`oddsback${key}${b_key}`] = back.odds;
                            }, 100);

                            return (
                                <div
                                    data-old={oddsChange.current[`oddsback${key}${b_key}`]}
                                    data-new={back.odds}
                                    className="box-1 back float-left text-center checkdataval"
                                    onClick={backFunction[b_key]}
                                    key={b_key}
                                >
                                    <span className="odd d-block">{back.odds || "-"}</span>
                                    <span className="d-block">{getSize(back.size) || ""}</span>
                                </div>
                            );
                        });

                        // Render lay odds
                        const layOdds = oddsArr.lay.slice(0, 1).map((lay, l_key) => {
                            lay.odds = tot === 0 ? '-' : lay.odds;
                            lay.size = tot === 0 ? '' : lay.size;

                            setTimeout(() => {
                                oddsChange.current[`oddslay${key}${l_key}`] = lay.odds;
                            }, 100);

                            const layFunction = generateBackAndLayFunction(
                                tot, oddsArr, 'lay', teamName, runnerRow, n_key, 'ODDS',
                                setBetOddValue, setbackOrLay, teamNames, setPopupDisplay,
                                setDefaultTeamName, runnerRowDefault, rootClassDefault, setBetType
                            );

                            return (
                                <div
                                    data-old={oddsChange.current[`oddslay${key}${l_key}`]}
                                    data-new={lay.odds}
                                    className={`box-1 lay${l_key === 0 ? '' : l_key} float-left text-center checkdataval`}
                                    onClick={layFunction[l_key]}
                                    key={l_key}
                                >
                                    <span className="odd d-block">{lay.odds || "-"}</span>
                                    <span className="d-block">{getSize(lay.size) || ""}</span>
                                </div>
                            );
                        });

                        return (
                            <div
                                data-title={isSuspended}
                                className={`table-row ${runnerRow} ${isSuspendedClass}`}
                                key={key}
                            >
                                <div className="float-left country-name box-4">
                                    <span className="team-name"><b>{teamName}</b></span>
                                    <p>
                                        <span className={`teamEx float-left`}>
                                            {getExByColor(teamEx, true)}
                                        </span>
                                    </p>
                                </div>
                                {backOdds}
                                {layOdds}
                                {oddsArr.lay.length === 1 && (
                                    <>
                                        <div className="box-1 lay1 lay-1 float-left hidden-portrait text-center">
                                            <span className="odd d-block">-</span>
                                            <span className="d-block">&nbsp;</span>
                                        </div>
                                        <div className="box-1 lay2 lay-2 float-left hidden-portrait text-center">
                                            <span className="odd d-block">-</span>
                                            <span className="d-block">&nbsp;</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                    {mainValue?.rem && (
                        <div className="market-row">
                            <marquee className="market-remark">{mainValue.rem}</marquee>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MatchOdds;
