import Header from "./Section/Header";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthContext} from "../Components/Context/AuthContext";
import {io} from 'socket.io-client'
import {Link} from "react-router-dom";
import {CommonContext} from "../Components/Context/CommonContext";

import {useNavigate} from "react-router-dom";
import {SportsContext} from "../Components/Context/SportsContext";
import {Buffer} from "buffer";

function Home() {

    const navigate = useNavigate();
    const {setShowLoader} = useContext(SportsContext)

    let Back1 = [];
    let Lay1 = [];
    let Back2 = [];
    let Lay2 = [];
    let cardGame = [];
    let videoGame = [];
    let BackX = [];
    let LayX = [];


    const BackAndLayForSports = (apiData, model) => {


        if (apiData && Object.keys(apiData).length > 0) {

            let detailArray = apiData.detailData || apiData.game_detail;

            if (!Array.isArray(detailArray)) {
                detailArray = [detailArray];
            }


            if (detailArray && detailArray[0] && detailArray[0].mname.toLowerCase() === 'match_odds') {
                const gameData = detailArray[0];
                const firstGame = gameData.section[0];
                const secondGame = gameData.section[1];
                const thirdGame = gameData.section[2] || null;


                const firstGameOddsBack = firstGame.odds.findIndex(o => o.oname === 'back1');
                const firstGameOddsLay = firstGame.odds.findIndex(o => o.oname === 'lay1');
                const secondGameOddsBack = secondGame.odds.findIndex(o => o.oname === 'back2');
                const secondGameOddsLay = secondGame.odds.findIndex(o => o.oname === 'lay1');

                Back1[model.match_id] = firstGame.odds[firstGameOddsBack]?.odds || 0;

                Lay1[model.match_id] = firstGame.odds[firstGameOddsLay]?.odds || 0;
                Back2[model.match_id] = secondGame.odds[secondGameOddsBack]?.odds || 0;
                Lay2[model.match_id] = secondGame.odds[secondGameOddsLay]?.odds || 0;


                if (thirdGame) {
                    const thirdGameOddsBack = thirdGame.odds.findIndex(o => o.oname === 'back2');
                    const thirdGameOddsLay = thirdGame.odds.findIndex(o => o.oname === 'lay1');
                    BackX[model.match_id] = thirdGame.odds[thirdGameOddsBack]?.odds || '-';
                    LayX[model.match_id] = (thirdGame.odds[thirdGameOddsLay]?.odds || '-');
                }
            }

            if (model.game_id === 1) {
                if (apiData.listData && apiData.game_detail) {
                    let listData = apiData.listData.ename ? apiData.listData : apiData.game_detail;

                    if (listData.gtype && listData.gtype !== 'match') {
                        cardGame[model.match_id] = 1;
                    }

                    if (listData.gtype === 'match' && (listData.iscc === 2 || listData.iscc === "2")) {
                        videoGame[model.match_id] = 1;
                    }
                }
            }
        }


    };

    const [matchesData, setMatchesData] = useState([])

    const [listData, setListData] = useState([]);

    const token = localStorage.getItem('token');
    useEffect(() => {
        let isMounted = true; // Flag to track if component is still mounted

        // Define the async function
        const fetchData = async () => {
            try {
                let response = await axios.get(process.env.REACT_APP_API_URL + 'getMatchesDataForSocket', {
                    headers: {Authorization: `Bearer ${token}`}
                });

                    setMatchesData(response.data); // Update state only if the component is still mounted
                    setShowLoader(false); // Hide loader after data is fetched

            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching data: ", error);
                    setShowLoader(false); // Hide loader if there's an error
                }
            }
        };

        // Execute the async function
        if (isMounted) {
            fetchData();
        }

        // Cleanup function to run when the component unmounts
        return () => {
            isMounted = false; // Set the flag to false to prevent state updates on unmounted component
        };
    }, [token]); // Add token as a dependency if needed


    useEffect(() => {


        if (matchesData !== null && matchesData.length > 0) {
            const list_socket = io(process.env.REACT_APP_LIST_URL)
            list_socket.emit('setPurposeFor', 'list', 1, '', '', matchesData)

            list_socket.on('getListData', userDatas => {
                if (userDatas !== null) {
                    userDatas = JSON.parse(Buffer.from(userDatas).toString('utf8'))

                    setListData(userDatas)

                }

            });
            return () => {
                list_socket.off('getListData'); // remove getListData event listener
                list_socket.disconnect(); // disconnect the socket
            };
        }
    }, [matchesData]);


    const [activeTab, setActiveTab] = useState('game1');


    const [tennisList, setTennisList] = useState([]);
    const [soccerList, setSoccerList] = useState([]);
    const {cricketList, unAuthorizeHandle, casinoList} = useContext(AuthContext);


    const {formatDateTime} = useContext(CommonContext);
    useEffect(() => {

        if (typeof token !== 'undefined') {
            loadCricketList()

        }

    }, []);


    useEffect(() => {

        localStorage.setItem('casinoList', JSON.stringify(casinoList))
    }, [casinoList])


    function loadCricketList() {


        const soccerRequest = axios.get(process.env.REACT_APP_API_URL + "soccer_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            if (error.code === 'ERR_NETWORK') {

                //console.clear();
                unAuthorizeHandle()

                return navigate('/login');
            }

        });

        const tennisRequest = axios.get(process.env.REACT_APP_API_URL + "tennis_list", {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).catch((e) => {

        });

// Now use axios.all to call all the APIs at the same time
        axios.all([soccerRequest, tennisRequest])
            .then(axios.spread((...responses) => {
                // The responses come back in an array

                const soccerResponse = responses[0];
                const tennisResponse = responses[1];

                // Check soccer data
                if (typeof soccerResponse.data !== 'undefined') {
                    if (Object.entries(soccerResponse.data).length > 0) {
                        setSoccerList(prevList => [...prevList, ...soccerResponse.data]);
                    }

                }
                // Check tennis data
                if (typeof tennisResponse.data !== 'undefined') {
                    if (Object.entries(tennisResponse.data).length > 0) {
                        setTennisList(prevList => [...prevList, ...tennisResponse.data]);
                    }
                }

            }))
            .catch(errors => {
                // react on errors.
                console.error(errors);
            });
    }


    const myGames = {
        'game_horse_racing': {name: "Horse Racing", i_class: "icon-10"},
        'game_table_tennis': {name: "Table Tennis", i_class: "icon-8"},
        'game_basketball': {name: "Basketball", i_class: "icon-15"},
        'game_volleyball': {name: "Volleyball", i_class: "icon-18"},
        'game_snooker': {name: "Snooker", i_class: "icon-59"},
        'game_ice_hockey': {name: "ICE Hockey", i_class: "icon-19"},
        'game_e_games': {name: "E Games", i_class: "icon-11"},
        'game_futsal': {name: "FUTSAL", i_class: "icon-9"},
        'game_handball': {name: "HandBall", i_class: "icon-39"},
        'game_kabaddi': {name: "Kabaddi", i_class: "icon-66"}
    };
    return (
        <div>
            <Header/>
            <div className="main-content">
                <ul className="nav nav-tabs game-nav-bar">

                    <li className="nav-item">
                        <a href="" className="nav-link">
                            Sports
                        </a>
                    </li>
                    <li className="nav-item">

                        <a href=""
                           className="nav-link">
                            Our Casino
                        </a>
                    </li>

                    <li className="nav-item blink_me">
                        <a href=""
                           className="nav-link router-link-exact-active router-link-active active blink_text">
                            V Casino
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href=""
                           className="nav-link">
                            Slots
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="" className="nav-link">
                            Fantasy
                        </a>
                    </li>
                </ul>
                <div data-v-51245d04="">
                    <div className="tab-content">
                        <div id="home" className="tab-pane sports active">
                            <ul className="nav nav-tabs game-nav-bar">

                                <li className="nav-item text-center">
                                    <Link data-toggle="tab"
                                          className={`nav-link ${activeTab === 'game1' ? 'active' : ''}`}
                                          onClick={() => setActiveTab('game1')}>
                                        <div>
                                            <i className="d-icon me-1 icon-4"
                                               style={{display: 'block', margin: '5px 0', fontSize: '16px'}}></i>
                                        </div>
                                        <div>Cricket</div>
                                    </Link>
                                </li>
                                <li className="nav-item text-center">
                                    <Link data-toggle="tab"
                                          className={`nav-link ${activeTab === 'game2' ? 'active' : ''}`}
                                          onClick={() => setActiveTab('game2')}>
                                        <div>

                                            <img src='../../img/sport/1.png' alt="Football"/>
                                        </div>
                                        <div>Football</div>
                                    </Link>
                                </li>
                                <li className="nav-item text-center">
                                    <Link data-toggle="tab"
                                          className={`nav-link ${activeTab === 'game3' ? 'active' : ''}`}
                                          onClick={() => setActiveTab('game3')}>
                                        <div>
                                            <i className="d-icon me-1 icon-2"
                                               style={{display: 'block', margin: '5px 0', fontSize: '16px'}}></i>
                                        </div>
                                        <div>Tennis</div>
                                    </Link>
                                </li>
                                {
                                    Object.keys(myGames).length > 0 &&
                                    Object.entries(myGames).map(([key, game]) =>
                                        <li className="nav-item text-center" key={key}>
                                            <a data-toggle="tab" href={`#${key}`}
                                               className="nav-link">
                                                <div className="d-xl-none">
                                                    <i className={`icon ${game.i_class}`}
                                                       style={{fontSize: '15px', margin: '5px 0', display: 'block'}}/>
                                                </div>
                                                <div>{game.name}</div>
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                            <div className="">
                                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="item">{/*todo active status*/}
                                            {/*<img src="{{ url('uploads/baner')."/". ${baner}.image_path }}" alt="Los Angeles" style={{width:'100%'}}/>*/}
                                        </div>
                                        {/* @endforeach */}
                                    </div>
                                    <ol className="carousel-indicators" style={{top: '1px'}}>
                                        {/*todo : html*/}
                                    </ol>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div id="game1"
                                     className={`tab-pane container pl-0 pr-0 ${activeTab === 'game1' ? 'active' : ''}`}>
                                    <div className="game-listing-container"
                                         style={{maxHeight: 'calc(-92px + 50vh)', overflowX: 'auto'}}>
                                        {
                                            cricketList.map((cricket, index) => {

                                                if (Object.keys(listData).length > 0) {
                                                    BackAndLayForSports(listData[cricket.match_id], cricket)

                                                } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                    BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                }


                                                return (


                                                    <div className="game-list pt-1 pb-1 container-fluid" key={index}>
                                                        <Link to={`cricket/${cricket.match_id}`}
                                                              className="">
                                                            <div className="row row5">
                                                                <div className="col-8">
                                                                    <p className="mb-0 game-name">
                                                                        <strong>{cricket.match_name}</strong>
                                                                    </p>
                                                                    <p className="mb-0">{formatDateTime(new Date(cricket.match_date_time))}</p>
                                                                </div>
                                                                <div className="col-4 text-right">
                                                                    <div className="game-icons">
                                                                        {cricket && cricket.isPlay !== 0 && (
                                                                            <span className="game-icon"><span
                                                                                className="active-icon"
                                                                                style={{verticalAlign: 'bottom'}}></span>    </span>
                                                                        )}
                                                                        <span className="game-icon"></span>
                                                                        <span className="game-icon"></span>
                                                                        {cricket && cricket.isBookmaker !== 0 && (
                                                                            <span className="game-icon bm-icon">
                                                                                            <img
                                                                                                src="../img/icons/ic_bm.png"
                                                                                                alt="bm icon"/>
                                                                                        </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row5">
                                                                <div className="col-12">
                                                                    <div className="text-center game-col game-home">
                                                                        <b>1</b>
                                                                    </div>
                                                                    <div className="text-center game-col game-home">
                                                                        <b>X</b>
                                                                    </div>
                                                                    <div className="text-center game-col game-home">
                                                                        <b>2</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="row row5">
                                                            <div className="col-12">
                                                                <div
                                                                    className={`text-center game-col game-home ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{Back1?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{Lay1?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                                <div
                                                                    className={`text-center game-col game-home ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{BackX?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{LayX?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                                <div
                                                                    className={`text-center game-col game-home ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{Back2?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{Lay2?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                {/* Rest of the game divs... */}
                                <div id='game2'
                                     className={`tab-pane container pl-0 pr-0 ${activeTab === 'game2' ? 'active' : ''}`}>
                                    <div className="game-listing-container"
                                         style={{maxHeight: 'calc(-92px + 50vh)', overflowX: 'auto'}}>
                                        {
                                            soccerList.map((cricket, index) => {

                                                if (Object.keys(listData).length > 0) {
                                                    BackAndLayForSports(listData[cricket.match_id], cricket)

                                                } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                    BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                }
                                                return (
                                                    <div className="game-list pt-1 pb-1 container-fluid" key={index}>
                                                        <Link to={`soccer/${cricket.match_id}`}
                                                              className="">
                                                            <div className="row row5">
                                                                <div className="col-8">
                                                                    <p className="mb-0 game-name">
                                                                        <strong>{cricket.match_name}</strong>
                                                                    </p>
                                                                    <p className="mb-0">{formatDateTime(new Date(cricket.match_date_time))}</p>
                                                                </div>
                                                                <div className="col-4 text-right">
                                                                    <div className="game-icons">
                                                                        {cricket && cricket.isPlay !== 0 && (
                                                                            <span className="game-icon"><span
                                                                                className="active-icon"
                                                                                style={{verticalAlign: 'bottom'}}></span>    </span>
                                                                        )}
                                                                        <span className="game-icon"></span>
                                                                        <span className="game-icon"></span>
                                                                        <span className="game-icon bm-icon">
                                                                                            <img
                                                                                                src="https://dxc247.com/all/images/mobile/ic_bm.png"
                                                                                                alt="bm icon"/>
                                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row5">
                                                                <div className="col-12">
                                                                    <div className="text-center game-col game-home">
                                                                        <b>1</b>
                                                                    </div>
                                                                    <div className="text-center game-col game-home">
                                                                        <b>X</b>
                                                                    </div>
                                                                    <div className="text-center game-col game-home">
                                                                        <b>2</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="row row5">
                                                            <div className="col-12">
                                                                <div
                                                                    className={`text-center game-col game-home ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{Back1?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{Lay1?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                                <div
                                                                    className={`text-center game-col game-home ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{BackX?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{LayX?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                                <div
                                                                    className={`text-center game-col game-home ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{Back2?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{Lay2?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                {/* Rest of the game divs... */}
                                <div id='game3'
                                     className={`tab-pane container pl-0 pr-0 ${activeTab === 'game3' ? 'active' : ''}`}>
                                    <div className="game-listing-container"
                                         style={{maxHeight: 'calc(-92px + 50vh)', overflowX: 'auto'}}>
                                        {
                                            tennisList.map((cricket, index) => {

                                                if (Object.keys(listData).length > 0) {
                                                    BackAndLayForSports(listData[cricket.match_id], cricket)

                                                } else if (Object.keys(JSON.parse(cricket.sport_new_data)).length > 0) {

                                                    BackAndLayForSports(JSON.parse(cricket.sport_new_data), cricket)
                                                }
                                                return (
                                                    <div className="game-list pt-1 pb-1 container-fluid" key={index}>
                                                        <Link to={`tennis/${cricket.match_id}`}>
                                                            <div className="row row5">
                                                                <div className="col-8">
                                                                    <p className="mb-0 game-name">
                                                                        <strong>{cricket.match_name}</strong>
                                                                    </p>
                                                                    <p className="mb-0">{formatDateTime(new Date(cricket.match_date_time))}</p>
                                                                </div>
                                                                <div className="col-4 text-right">
                                                                    <div className="game-icons">
                                                                        {cricket && cricket.isPlay !== 0 && (
                                                                            <span className="game-icon"><span
                                                                                className="active-icon"
                                                                                style={{verticalAlign: 'bottom'}}></span>    </span>
                                                                        )}
                                                                        <span className="game-icon"></span>
                                                                        <span className="game-icon"></span>
                                                                        <span className="game-icon bm-icon">
                                                                                            <img
                                                                                                src="https://dxc247.com/all/images/mobile/ic_bm.png"
                                                                                                alt="bm icon"/>
                                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row5">
                                                                <div className="col-12">
                                                                    <div className="text-center game-col game-home">
                                                                        <b>1</b>
                                                                    </div>
                                                                    <div className="text-center game-col game-home">
                                                                        <b>X</b>
                                                                    </div>
                                                                    <div className="text-center game-col game-home">
                                                                        <b>2</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="row row5">
                                                            <div className="col-12">
                                                                <div
                                                                    className={`text-center game-col game-home ${Back1?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{Back1?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{Lay1?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                                <div
                                                                    className={`text-center game-col game-home ${BackX?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>
                                                                    <a className="btn-back">{BackX?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{LayX?.[cricket.match_id] ?? '-'}</a>
                                                                </div>

                                                                <div
                                                                    className={`text-center game-col game-home ${Back2?.[cricket.match_id] > 0 ? '' : 'suspended-box'}`}>

                                                                    <a className="btn-back">{Back2?.[cricket.match_id] ?? '-'}</a>
                                                                    <a className="btn-lay">{Lay2?.[cricket.match_id] ?? '-'}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane active casino-tables">
                    <div className="container-fluid container-fluid-5">
                        <div className="row row5">
                            <div className="col-12">
                                <h4 className="text-uppercase mt-3">Live Casinos </h4>
                            </div>
                        </div>
                        <div className="row row5 mt-2 loader-class" id="load_images">

                            {casinoList.map((casino, index) => (
                                <div key={index} className="col-3 text-center">
                                    <div className="casinoicons" style={{boxShadow: 'none'}}>
                                        <Link to={"casino/" + casino.match_id} className="">
                                            <img src={"../uploads/casino/" + casino.casino_image}
                                                 alt={casino.match_name}
                                                 className="img-fluid"/>

                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}


export default Home;