import React, {useContext, useEffect, useRef, useState} from "react";
import Header from "../Section/Header";
import SportsLayout from "../Section/SportsLayout";
import {mapSports, getExByTeamNameForCricket} from "../../Common_Functions/Constants";
import {SportsContext} from "../../Components/Context/SportsContext";
import BetPlacePopup from "../../Components/BetPlacePopup";
import MatchOdds from "../Section/AllSports/MatchOdds";
import Bookmaker from "../Section/AllSports/Bookmaker";
import {useNavigate} from "react-router-dom";

const Tennis = () => {
    const nav = useNavigate();

    const defaultTeamName = useRef('')


    const teamNames = useRef({});
    const [betOddValue, setBetOddValue] = useState(0)
    const [backOrLay, setbackOrLay] = useState('back')
    const {popupDisplay, setPopupDisplay, betType, sports_socket, betPlaceStatusCheck, betPlaceStatus,setShowLoader} = useContext(SportsContext)


    const [sportList, setSportList] = useState({})
    const [ar_sectionData, setAr_sectionData] = useState([])



    const oddsChange = useRef({});

    const [oddsTeamData, setOddsTeamData] = useState({})
    const [bookmakerTeamData, setBookmakerTeamData] = useState({})

    let gameSet = [];



    useEffect(() => {
        if (Object.keys(sportList).length > 0) {
            setOddsTeamData(getExByTeamNameForCricket(ar_sectionData, 'ODDS', 'match_odds', sportList.id));
            setBookmakerTeamData(getExByTeamNameForCricket(ar_sectionData, 'BOOKMAKER', 'bookmaker', sportList.id));

        }


    }, [sportList]);

    const callTeamDatas = async (bet_type = null) => {
        try {

            const oddsTeamData = await getExByTeamNameForCricket(ar_sectionData, 'ODDS', 'match_odds', sportList.id);
            const bookmakerTeamData = await getExByTeamNameForCricket(ar_sectionData, 'BOOKMAKER', 'bookmaker', sportList.id);

            // Set the states after all data is fetched
            setOddsTeamData(oddsTeamData);
            setBookmakerTeamData(bookmakerTeamData);

        } catch (error) {

        }
    };
    const arlength = Object.keys(ar_sectionData).length

    useEffect(() => {
        if (Object.keys(sportList).length > 0 && arlength > 0) {
            callTeamDatas();
        }

    }, [sportList, arlength, localStorage.getItem('exposure')]);

    useEffect(() => {
        let gamename = mapSports[sportList.game_id]
        sports_socket.emit('setPurposeFor', 'sports', gamename, '', '', sportList.match_id)
        let socket_game = `getSportData${gamename}${sportList.match_id}`;

        if (Object.keys(sportList).length > 0) {


            const gameConnect =() => {
                sports_socket.on(socket_game, sportData => {



                    if (sportData !== null) {


                        sportData =JSON.parse(Buffer.from(sportData).toString('utf8'))

                        if (sportData && sportData.game_detail && sportData.game_detail.length > 0) {
                            setShowLoader(false);

                            for (let i = 0; i < sportData.game_detail.length; i++) {
                                const value = sportData.game_detail[i];

                                const gtype = value.mname.toLowerCase();



                                gameSet[gtype] = value;
                            }
                            setAr_sectionData(gameSet)
                        }
                        else{
                            nav('/')
                        }



                    }
                    const elements = document.getElementsByClassName('checkdataval');

                    for (let i = 0; i < elements.length; i++) {
                        let element = elements[i];
                        // compare data-old and data-new attributes
                        let oldData = element.getAttribute('data-old');
                        let newData = element.getAttribute('data-new');


                        if (oldData !== newData) {
                            // if data has changed, add the 'color-yellow-bet' class
                            element.classList.add('color-yellow-bet');
                        } else {
                            element.classList.remove('color-yellow-bet');
                        }

                    }
                })
            }
            gameConnect();

            sports_socket.on('disconnect', function() {
                console.log('Disconnected from server. Attempting to reconnect...');

                // Reconnect logic
                const connectInterval = setInterval(() => {
                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });

            return () => {

                if (sports_socket.connected) {

                    sports_socket.off(socket_game);  // Remove listener

                }
            };

        }

    }, [sportList]);


    const individualBetPlaceFetch = (teamname) => {
        let r = betPlaceStatusCheck(sportList, ar_sectionData, teamname)

        betPlaceStatus.current[teamname] = r
    }
    return (
        <div>
            <Header/>
            <SportsLayout sportList={sportList} setSportList={setSportList} data={ar_sectionData}>

                <BetPlacePopup individualBetPlaceFetch={individualBetPlaceFetch} data={ar_sectionData}   betType={betType} sportList={sportList} backOrLay={backOrLay} teamname={defaultTeamName}
                               odds={betOddValue} teamNames={teamNames} setOdds={setBetOddValue}
                               setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay}/>
                <MatchOdds oddsTeamData={oddsTeamData} setDefaultTeamName={defaultTeamName}
                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                           setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData} sportList={sportList}
                           oddsChange={oddsChange}/>
                <Bookmaker setDefaultTeamName={defaultTeamName} bookmakerTeamData={bookmakerTeamData} setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay}
                           teamNames={teamNames} setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData}
                           sportList={sportList} oddsChange={oddsChange}/>


            </SportsLayout>
        </div>

    );
};


export default Tennis;
