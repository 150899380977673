import React, { useContext, useEffect, useRef, useState} from "react";
import Header from "../Section/Header";
import SportsLayout from "../Section/SportsLayout";
import {
    getExByTeamNameForCricket,
    mapSports,
    showCricketSessionBook
    } from "../../Common_Functions/Constants";

import {SportsContext} from "../../Components/Context/SportsContext";
import BetPlacePopup from "../../Components/BetPlacePopup";
import Bookmaker from "../Section/AllSports/Bookmaker";
import MatchOdds from "../Section/AllSports/MatchOdds";
import TiedMatch from "../Section/Cricket/TiedMatch";
import FancySession from "../Section/Cricket/FancySession";

import OverByOver from "../Section/Cricket/OverByOver";
import Scoreboard from "../Section/Cricket/Scoreboard";
import Meter from "../Section/Cricket/Meter";
import OddEven from "../Section/Cricket/OddEven";
import Fancy1 from "../Section/Cricket/Fancy1";
import Khado from "../Section/Cricket/Khado";
import {useNavigate, useParams} from "react-router-dom";
import BallByBall from "../Section/Cricket/BallByBall";
import {Buffer} from "buffer";



const Cricket = () => {
    const nav = useNavigate();

    const [ar_sectionData, setAr_sectionData] = useState([])
    const {match_id} = useParams();
    const teamNames = useRef({});
    const defaultTeamName = useRef('')
    const [betOddValue, setBetOddValue] = useState(0)
    const [backOrLay, setbackOrLay] = useState('back')
    const {popupDisplay, setPopupDisplay, betType, sports_socket, betPlaceStatusCheck,setShowLoader, betPlaceStatus} = useContext(SportsContext)

    const [sportList, setSportList] = useState({})

    const oddsChange = useRef({});
    const individualBetPlaceFetch = (teamname) => {
        let r = betPlaceStatusCheck(sportList, ar_sectionData, teamname)

        betPlaceStatus.current[teamname] = r
    }
    const ar_lengt = Object.keys(ar_sectionData).length;

    useEffect(() => {
    // axios.post('https://dxc247.com:8000/check-credentials/5664646')

        if(ar_lengt > 0){
            callTeamDatas();
        }


    }, [ar_lengt, localStorage.getItem('exposure')]);
    useEffect(() => {


        betPlaceStatusCheck(sportList, ar_sectionData)

    }, [ar_lengt]);

    const [oddsTeamData, setOddsTeamData] = useState({})
    const [bookmakerTeamData, setBookmakerTeamData] = useState({})
    const [tiedMatchData, setTiedMatchData] = useState({})

    const callTeamDatas = async () => {

        try {
            if (Object.keys(sportList).length > 0) {
                setOddsTeamData(getExByTeamNameForCricket(ar_sectionData, 'ODDS', 'match_odds', sportList.id));
                setBookmakerTeamData(getExByTeamNameForCricket(ar_sectionData, 'BOOKMAKER', 'bookmaker', sportList.id));
                setTiedMatchData(getExByTeamNameForCricket(ar_sectionData, 'TIED_MATCH', 'tied match', sportList.id));

            }


        } catch (error) {
            console.error("Error fetching team data:", error);
        }
    };


    useEffect(() => {

        if (Object.keys(sportList).length > 0) {

            console.log('cricket ttt')
            let gamename = mapSports[sportList.game_id]
            sports_socket.emit('setPurposeFor', 'sports', gamename, '', '', match_id)
            let socket_game = `getSportData${gamename}${match_id}`;

            const gameConnect = () => {
                sports_socket.on(socket_game, sportData => {

                    if (sportData !== null) {
                    sportData = JSON.parse(Buffer.from(sportData).toString('utf8'))

                        let gameSet = [];

                        if (sportData && sportData.game_detail && sportData.game_detail.length > 0) {
                            setShowLoader(false);

                            for (let i = 0; i < sportData.game_detail.length; i++) {
                                const value = sportData.game_detail[i];

                                const gtype = value.mname.toLowerCase();


                                gameSet[gtype] = value;
                            }
                            setAr_sectionData(gameSet)

                        }
                        else{
                            nav('/')
                        }

                    }


                })
            }
            gameConnect();

            sports_socket.on('disconnect', function () {

                // Reconnect logic
                const connectInterval = setInterval(() => {

                    gameConnect();
                    clearInterval(connectInterval)
                }, 1000);  // Reattempt connect every second
            });


            return () => {

                sports_socket.off(socket_game);

            };

        }

    }, [sportList]);



    useEffect(() => {
        const elements = document.getElementsByClassName('checkdataval');
        const elementsArray = Array.from(elements); // Convert HTMLCollection to Array for better array methods

        elementsArray.forEach((element) => {
            const oldData = element.getAttribute('data-old');
            const newData = element.getAttribute('data-new');

            if (oldData !== newData) {
                // Add class if data has changed
                element.classList.add('color-yellow-bet');
            } else {
                // Remove class if data hasn't changed
                element.classList.remove('color-yellow-bet');
            }
        });
    }, [ar_sectionData]);



    return (
        <div>
            <Header/>
            <SportsLayout sportList={sportList} data={ar_sectionData} setSportList={setSportList}>
                <Scoreboard/>



                <BetPlacePopup individualBetPlaceFetch={individualBetPlaceFetch} data={ar_sectionData}   betType={betType} sportList={sportList} backOrLay={backOrLay} teamname={defaultTeamName}
                               odds={betOddValue} teamNames={teamNames} setOdds={setBetOddValue}
                               setPopupDisplay={setPopupDisplay} popupDisplay={popupDisplay}/>
                <MatchOdds oddsTeamData={oddsTeamData} setDefaultTeamName={defaultTeamName}
                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} teamNames={teamNames}
                           setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData} sportList={sportList}
                           oddsChange={oddsChange}/>
                <Bookmaker setDefaultTeamName={defaultTeamName} bookmakerTeamData={bookmakerTeamData} setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay}
                           teamNames={teamNames} setPopupDisplay={setPopupDisplay} ar_sectionData={ar_sectionData}
                           sportList={sportList} oddsChange={oddsChange}/>


                <TiedMatch model={sportList} tiedMatchData={tiedMatchData} gameData={ar_sectionData} teamNameArr={teamNames}

                           setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay}  teamNames={teamNames} setPopupDisplay={setPopupDisplay} setDefaultTeamName={defaultTeamName}
                />
                <FancySession betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName} setPopupDisplay={setPopupDisplay} sportList={sportList}
                              oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                              setBetOddValue={setBetOddValue} data={ar_sectionData}
                              showCricketSessionBook={showCricketSessionBook}
                />
                <OverByOver betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName} setPopupDisplay={setPopupDisplay}
                            model={sportList} oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                            setBetOddValue={setBetOddValue} gameData={ar_sectionData}
                            showCricketSessionBook={showCricketSessionBook}/>
                <BallByBall betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName} setPopupDisplay={setPopupDisplay} sportList={sportList}
                              oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                              setBetOddValue={setBetOddValue} data={ar_sectionData}
                              showCricketSessionBook={showCricketSessionBook}
                />

                <Fancy1 betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName} setPopupDisplay={setPopupDisplay} sportList={sportList}
                              oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                              setBetOddValue={setBetOddValue} data={ar_sectionData}

                />


                <Khado betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName} setPopupDisplay={setPopupDisplay} sportList={sportList}
                              oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                              setBetOddValue={setBetOddValue} data={ar_sectionData}
                              showCricketSessionBook={showCricketSessionBook}
                />

                <OddEven setBetOddValue={setBetOddValue} setbackOrLay={setbackOrLay} setDefaultTeamName={defaultTeamName} model={sportList} gameData={ar_sectionData}
                         setPopupDisplay={setPopupDisplay}/>
                <Meter betPlaceStatus={betPlaceStatus} setDefaultTeamName={defaultTeamName} setPopupDisplay={setPopupDisplay} sportList={sportList}
                              oddsChange={oddsChange} teamNames={teamNames} setbackOrLay={setbackOrLay}
                              setBetOddValue={setBetOddValue} data={ar_sectionData}
                              showCricketSessionBook={showCricketSessionBook}
                />

            </SportsLayout>
        </div>

    );
};
export default Cricket;