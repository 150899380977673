import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from './Login';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./Mobile/Home";
import ProtectedRoute from "./ProtectedRoute";
import {AuthProvider} from "./Components/Providers/AuthProvider";
import Tennis from "./Mobile/SportsData/Tennis";
import Cricket from "./Mobile/SportsData/Cricket";
import Soccer from "./Mobile/SportsData/Soccer";
import {CommonProvider} from "./Components/Providers/CommonProvider";
import {SportsProvider} from "./Components/Providers/SportsProvider";
import AccountStatement from "./Mobile/AccountStatement";
import ProfitLossReport from "./Mobile/ProfitLossReport";
import BetHistories from "./Mobile/BetHistories";
import {Rules} from "./Mobile/Rules";
import CasinoResultReport from "./Mobile/CasinoResultReport";
import CasinoMain from "./Mobile/Section/Casino/CasinoMain";
import {CasinoProvider} from "./Components/Providers/CasinoProvider";


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>

        <CommonProvider>
            <SportsProvider>
        <CasinoProvider>
                <Router>
                    <AuthProvider>
                        <Routes>

                            <Route path="/login" element={<Login/>}/>

                            <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
                            <Route path="/tennis/:match_id" element={<ProtectedRoute><Tennis/></ProtectedRoute>}/>

                            <Route path="/account-statement" element={<ProtectedRoute><AccountStatement/></ProtectedRoute>}/>
                            <Route path="/profit-loss" element={<ProtectedRoute><ProfitLossReport/></ProtectedRoute>}/>
                            <Route path="/bet-history" element={<ProtectedRoute><BetHistories/></ProtectedRoute>}/>
                            <Route path="/rules" element={<ProtectedRoute><Rules/></ProtectedRoute>}/>
                            <Route path="/casino-game-results" element={<ProtectedRoute><CasinoResultReport/></ProtectedRoute>}/>
                            <Route path="/casino/:match_id" element={<ProtectedRoute><CasinoMain/></ProtectedRoute>}/>


                            <Route path="/soccer/:match_id" element={<ProtectedRoute><Soccer/></ProtectedRoute>}/>
                            <Route path="/cricket/:match_id" element={<ProtectedRoute><Cricket/></ProtectedRoute>}/>
                        </Routes>
                    </AuthProvider>
                </Router>
        </CasinoProvider>
            </SportsProvider>
        </CommonProvider>

    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

